import React, { useState } from "react";



export default function Loesungeingabe() {
    const [antwort, setAntwort] = useState("");
    const [valida, setValida] = useState("");
    const [atw, setAtw] = useState("");

    const register = () => {
        if (antwort === "Alexander" || antwort === "alexander") {
            setValida("");
            setAtw("Selbstmord? Nein.");
        } else if ((antwort === "Simon" || antwort === "simon")) {
            setValida("");
            setAtw("Korrekt! Herzlichen Glückwunsch! Eure Sonderkommission ist den hohen Erwartungen des Polizeipräsidiums gerecht geworden. Durch Eure Ermittlungsarbeit habt ihr den Täter erfolgreich ermittelt!");
        } else if ((antwort === "Carolin" || antwort === "carolin")) {
            setValida("");
            setAtw("Carolin Käufer ist nicht die Täterin. Zur Tatzeit hat sie für ihren Nachbarn ein Paket entgegen genommen. Sie hat also ein Alibi.");
        } else if ((antwort === "Nico" || antwort === "nico")) {
            setValida("");
            setAtw("Nico Neumann hat ein Alibi. Er ist zwar sehr zwielichtig, und ein Motiv hat er auch. Aber zum Tatzeitpunkt war der Lieferwagen seines Unternehmens in Grabenbruck unterwegs und hat dort für Falschparken ein Knöllchen bekommen. Da der einzige Mitarbeiter an diesem Tag krankgeschrieben war, und alle Lieferungen persönlich erfolgen, muss also Nico Neumann zum Tatzeitpunkt in Grabenbruck gewesen sein.");
        } else if ((antwort === "Theresa" || antwort === "theresa" || antwort === "Teresa" || antwort === "teresa")) {
            setValida("");
            setAtw("Theresa Mink hat ein Alibi. In Alexander Käufers Emails erfahren wir, dass sie zum Tatzeitpunkt beim Notar in Grabenbruck saß.");
        } else if ((antwort === "Michaela" || antwort === "michaela")) {
            setValida("");
            setAtw("Michaela Maurer hat ein Alibi. Zum Tatzeitpunkt hat sie beim Online-Spiel \"Flottfight\" einen neuen Rekord aufgestellt.");
        } else if ((antwort === "Markus" || antwort === "markus" || antwort === "Marcus" || antwort === "marcus")) {
            setValida("");
            setAtw("Markus Mink hat ein Alibi. In Alexander Käufers Emails erfahren wir, dass er zum Tatzeitpunkt beim Notar in Grabenbruck saß.");
        } else if ((antwort === "Bianca" || antwort === "bianca")) {
            setValida("");
            setAtw("Bianca Bauer hat ein Alibi. Zum Tatzeitpunkt war sie in der Bücherei und hat Bücher ausgeliehen.");
        } else if ((antwort === "Hans" || antwort === "hans")) {
            setValida("");
            setAtw("Hans Hoppe sitzt im Gefängnis, und hat deshalb ein Alibi.");
        } else if ((antwort === "Heike" || antwort === "heike")) {
            setValida("");
            setAtw("Heike Hoppe hat ein Alibi. Sie war spätestens um 08:50 Uhr zu Besuch im Gefängnis in Grabenbruck. Zu weit weg von Tillstetten um die Tat begangen haben zu können.");
        } else if ((antwort === "Ludwig" || antwort === "ludwig")) {
            setValida("");
            setAtw("Ludwig Liebig hat ein Alibi. Er war zur Tatzeit in Grabenbruck (oder auf dem Weg dort hin) um an einer Sitzung des FC Grabenbruck teilzunehmen.");
        } else if ((antwort === "Jana" || antwort === "jana")) {
            setValida("");
            setAtw("Jana Jäckel hat ein Alibi. Sie war zur Tatzeit am Flughafen und hat ihre verlorene Brille im Fundbüro abgeholt.");
        } else if ((antwort === "Lilly" || antwort === "lilly")) {
            setValida("");
            setAtw("Lilly Liebig hat ein Alibi. Sie war zur Tatzeit in Grabenbruck (oder auf dem Weg dort hin) um an einer Sitzung des FC Grabenbruck teilzunehmen.");
        } else if (antwort === "Erik" || antwort === "erik" || antwort === "eric" || antwort === "Eric") {
            setValida("");
            setAtw("Erik Lindt hat kein Motiv. Da er nicht auf dem Poster der Verdächtigen ist, glauben wir ihm mal, dass er an diesem Tag krank war, und wie sich das gehört zu Hause war, um sich auszukurrieren.");
        } else if (antwort === "") {
            setValida("");
            setAtw("");
        }

        else {
            setValida(
                "Upps, etwas ist schiefgelaufen. Ist die Schreibweise korrekt?. Gebt bitte nur den Vornamen ohne Leerzeichen ein."
            );
            setAtw("");

        }
    };

    return (

        <div>
            <input
                className="inputb ten-margin antwort"
                type="text"
                value={antwort}
                onChange={(e) => setAntwort(e.target.value)}
                placeholder="Vorname Täter/in"
            />{" "}
            <button className="importbutton" onClick={register}>
                Absenden
              </button>
            <p className="valii">{valida}</p>
            <p className="antword">{atw}</p>
            <div className="luecke"></div>
        </div>
    );
}