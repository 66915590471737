import React, { useState } from "react";
import AppContext from "./AppContext";

const ReContext = ({ children }) => {
  const [cdr, setCdr] = useState("1");
  const [reg, setReg] = useState(false);
  const [auf, setAuf] = useState(false);
  const [payed, setPayed] = useState(false);
  const [ant, setAnt] = useState("");
  const [eco, setEco] = useState("none");
  const [erm, setErm] = useState(false);
  // const [mai, setMai] = useState(false);

  const context = {
    cdr,
    setCdr,
    reg,
    setReg,
    auf,
    setAuf,
    payed,
    setPayed,
    ant,
    setAnt,
    eco,
    setEco,
    erm,
    setErm,
  };
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
export default ReContext;
