import React, { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Code from "../components/Code";
import Einloggen from "../logs/Einloggen";

import { AuthContext } from "../provider/AuthProv";
import { Navigate } from "react-router-dom";
import Registrieren from "../logs/Registrieren";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

export default function RezOnline() {
  const { currentUser, isLogged } = useContext(AuthContext);

  const navigate = useNavigate();
  function handleClick() {
    navigate("/");
  }

  const displayThis = isLogged ? (
    <Navigate to={`/app/rezeptonline`} />
  ) : (
    <div>
      <Einloggen />
      <Code />

      <Registrieren />
    </div>
  );

  return (
    <Fragment>
      <div>
        <AppBar
          position="static"
          color="default"
          style={{ background: "#9ad3bc" }}
        >
          <Toolbar>
            <ArrowBackIcon onClick={handleClick} />

            <Typography variant="h6">App</Typography>
          </Toolbar>
        </AppBar>
      </div>

      <div className="centering wcentering">{displayThis}</div>
    </Fragment>
  );
}
