import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import EMotiv from "../components/crezept/EMotiv";

export default function Motive() {
  const navigate = useNavigate();
  function zuru() {
    navigate("/rezept");
  }

  const [auf, setAuf] = useState(false);

  return (
    <Fragment>
      <div className="rbckr">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={{ background: "#527318" }}>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="arrowBack"
                sx={{ mr: 2 }}
                onClick={zuru}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
                align="center"
              >
                Tipps zu Motiven
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="marginround">
            <div className="rcentering txtkleiner txthellg abold">
              <div className="abstandbar"></div>
              <div>
                <p>
                  Es ist ungewiss, ob Henrik Olsen überhaupt das Ziel der
                  Vergiftung sein sollte.
                </p>
                <p>
                  Klickt auf einen Namen, um zu sehen, wer ein Motiv gehabt
                  haben könnte, diese Person zu vergiften:
                </p>
                <Grid
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 1, sm: 16, md: 18 }}
                  onClick={() => setAuf(!auf)}
                >
                  {Array.from(Array(12)).map((_, index) => (
                    <EMotiv indi={index} />
                  ))}
                </Grid>
              </div>
            </div>
          </div>
        </Box>
        <div className="abstanduklein"></div>
      </div>
    </Fragment>
  );
}
