import React, { useState, Fragment } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import hund from "../../hund.png";

export default function Kai() {
  const [kaiauf, setKaiauf] = useState(false);
  const [kaimotiv, setKaimotiv] = useState(false);

  return (
    <Fragment>
      <div>
        <p>Korrekt! Kai ist der Täter.</p>
        <p>
          Eure Sonderkommission ist den hohen Erwartungen des Polizeipräsidiums
          gerecht geworden. Durch Eure Ermittlungsarbeit habt ihr den Täter
          erfolgreich ermittelt!
        </p>
        <p>
          Kai greift während Katrin schläft heimlich auf ihr Smartphone zu
          (Aufzeichnung des Schlafs durch Smartwatch und Zugriffe auf ihr
          Handy). Dadurch kennt Kai alle Informationen aus Katrins
          Chatverläufen.
        </p>
        {kaiauf ? (
          <div className="txtdunkelg">
            <p onClick={() => setKaiauf(!kaiauf)} className="pointer">
              <RemoveIcon fontSize="inherit" />{" "}
              <span className="abold">
                {" "}
                Motiv (1): Henrik ist ihm auf der Spur
              </span>
            </p>
            <p>
              Da Henrik im Chat mit Katrin nach dem Namensgeber ihrer
              Eichhörnchen fragt, weiß Kai, dass Henrik drauf und dran ist, ihn
              als denjenigen zu enttarnen, der heimlich
              Hulei-chem-Firmengeheimnisse an Tilmans Tierschutzorganisation
              weitergegeben hat (Rundmail Hulei-chem). Kai hat scheinbar ein
              besonderes Interesse für Neuere Deutsche Geschichte. Er hat sich
              verraten, da er sowohl als Pseudonym bei der Weitergabe der
              Firmengeheimnisse, als auch bei der Benennung seiner Eichhörnchen
              die Namen deutscher Kanzler verwendet.
            </p>{" "}
          </div>
        ) : (
          <p onClick={() => setKaiauf(!kaiauf)} className="pointer txtdunkelg">
            <AddIcon fontSize="inherit" />
            <span className="abold">
              {" "}
              Motiv (1): Henrik ist ihm auf der Spur
            </span>
          </p>
        )}

        {kaimotiv ? (
          <div className="txtdunkelg">
            <p onClick={() => setKaimotiv(!kaimotiv)} className="pointer">
              <RemoveIcon fontSize="inherit" />{" "}
              <span className="abold">
                {" "}
                Motiv (2): Karrierechance USA und Rache an Katrin
              </span>
            </p>
            <p>
              Sowohl Henrik, als auch Katrin sind sich einig, dass ein Umzug in
              die USA nicht in Frage kommt. Wäre Henrik an der Vergiftung
              gestorben, hätte Ophelia die nächste Karrierestufe in den USA
              wahrnehmen können (Henriks Chat mit Katrin, Henrik blockiert das),
              was auch für Kai weiteren beruflichen Aufstieg bedeutet hätte
              (Magazin-Artikel, KarriereNetz-Profil). Der anonyme Hinweis auf
              Katrin (Auftragsschreiben) kommt vermutlich von Kai. Denn käme
              Katrin, die ja tatsächlich mit dem Wrap den Hund vergiften wollte,
              für fahrlässiges Handeln oder sogar Mord ins Gefängnis, könnte Kai
              mit der gemeinsamen Tochter in die USA umziehen. Damit hätte er
              sich zudem gerächt, dass sie eine Affäre mit Lars hat.
            </p>{" "}
          </div>
        ) : (
          <p
            onClick={() => setKaimotiv(!kaimotiv)}
            className="pointer txtdunkelg"
          >
            <AddIcon fontSize="inherit" />
            <span className="abold">
              {" "}
              Motiv (2): Karrierechance USA und Rache an Katrin
            </span>
          </p>
        )}

        <p>
          Durch das Stöbern in Katrins Smartphone hatte Kai alle nötigen
          Informationen, um die Tat zu begehen. Auch die Beschreibung des jungen
          Manns an der Supermarktkasse mit dem verlorenen Geldbeutel passt auf
          Kai (Mail Filialleiter Supermarkt). Er hat dort zufällig Luna oder
          Robin getroffen, die für Kai die Käsepicker-Fähnchen mitbezahlt haben
          (Kassenbeleg Supermarkt). Die Dankkarte mit den 2 Euro hat er an dem
          Abend vermutlich vergessen Luna oder Robin zu geben.
        </p>
        <p>
          Kai hat also gewusst, dass der Wrap mit der US-Flagge vergiftet sein
          würde. Er hat deshalb selbst kleine Käsepicker-Fähnchen im Supermarkt
          besorgt. Da Henrik aus Dänemark kommt, hat Kai das Fähnchen auf dem
          vergifteten Wrap heimlich gegen ein Dänemark-Fähnchen vertauscht. So
          manipulierte er Henrik, der zuvor seine Diät aufgegeben hatte, den
          vergifteten Wrap zu nehmen und zu essen.
        </p>
        <img className="start-img" src={hund} alt="Henriks Hund" />
      </div>
    </Fragment>
  );
}
