import React, { Fragment, useState } from 'react'
import firebase from '../firebase'

const Einloggen = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [wrongEP, setWrongEP] = useState(false)

  const [open, setOpen] = useState(false)
  const [reg, setReg] = useState(false)

  const login = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        resetInput()
        setWrongEP(false)
        console.log('Eingeloggt')
      })
      .catch((err) => {
        console.error(err)
        console.log('Binn jetzt im Fehler')
        setWrongEP(true)
      })
  }

  const resetInput = () => {
    setEmail('')
    setPassword('')
  }

  function handleLog() {
    setOpen(true)
    setReg(false)
  }

  return (
    <Fragment>
      <div className="centerix centerios abstando">
        {!open ? (
          <div>
            {!reg ? (
              <button className="butto" onClick={() => setOpen(!open)}>
                Einloggen
              </button>
            ) : null}
          </div>
        ) : (
          <div>
            <div>
              <h3 onClick={() => setOpen(!open)}>
                Gib die Zugangsdaten ein, die Du beim Kauf angegeben hast:
              </h3>
            </div>
            <div>
              <input
                className="inputb"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email"
              />{' '}
              <br />
              <input
                className="inputb"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="password"
              />
              <br />
              <button className="butto" onClick={login}>
                Login
              </button>
              <br />
            </div>
            {wrongEP ? (
              <p>Login fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben</p>
            ) : null}
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default Einloggen
