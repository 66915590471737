import React, { useState, Fragment } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function LFrage() {
  const [kaiauf, setKaiauf] = useState(false);

  return (
    <Fragment>
      <div>
        <div className="txthellg">
          <p>Ihr habt den Fall schon gelöst?</p> <p>Sicher?</p>{" "}
          <p>
            Für einen Teil des Motivs spielt die Spur "Bundeskanzler" eine
            wichtige Rolle...
          </p>
        </div>
        {kaiauf ? (
          <div className="txtdunkelg">
            <p onClick={() => setKaiauf(!kaiauf)} className="pointer">
              <RemoveIcon fontSize="inherit" />{" "}
              <span className="abold"> Tipp zur dieser Spur</span>
            </p>
            <p>
              Auf der Seite des Polizeiportals, auf der Euch das LKA die
              Ergebnisse der Durchsuchung bereitstellt, ist oben ein kleiner
              Hinweis auf eine Vortragsreihe von Prof. Dr. Gellwieser an der
              Polizeiakademie. Die Namen der Kanzler die Gegenstand der Vorträge
              sind, werdet Ihr in den Indizien wieder finden.{" "}
            </p>
            <p>
              Auch Henrik scheint in seinem Chat mit Katrin einen Verdacht
              bezüglich dieser Namen gehabt haben.
            </p>{" "}
          </div>
        ) : (
          <p onClick={() => setKaiauf(!kaiauf)} className="pointer txtdunkelg">
            <AddIcon fontSize="inherit" />
            <span className="abold"> Tipp zur dieser Spur</span>
          </p>
        )}
      </div>
    </Fragment>
  );
}
