import React, { useState, useEffect, useContext } from "react";

import firebase from "../firebase";
import AppContext from "../provider/AppContext";
import { useNavigate } from "react-router-dom";
import CodeR from "../components/CodeR";
import Estart from "../components/erez/Estart";

export default function Ermittlung() {
  const { cdr, setCdr, erm } = useContext(AppContext);
  // const ref = firebase.firestore().collection(cdr);
  const ref = firebase.firestore().collection("coll");
  const [ladn, setLadn] = useState(true);

  const [vld, setVld] = useState(false);

  const navigate = useNavigate();
  function handleClick() {
    navigate("/krimispiel");
  }

  const date = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);

  // 328004515

  useEffect(() => {
    if (cdr !== "1" && typeof cdr !== "undefined") {
      console.log(typeof cdr, cdr);
      const unsubscribe = ref.doc(cdr).onSnapshot((doc) => {
        // setSwiv(doc.data());
        // console.log(doc.data().active, "doc.data().active");
        let codeDate;
        console.log(doc.data());
        codeDate = doc.data().crtd;
        if (codeDate.toDate() > date) {
          setVld(true);
        } else {
          setVld(false);
        }
      });
      setLadn(false);
      return () => {
        unsubscribe();
      };
    } else if (cdr === "1") {
      console.log("eess block");
    }
  }, []);

  return erm === false ? (
    <div>
      <p>
        Um Deinen Code einzugeben, klicke{" "}
        <span className="abold" onClick={handleClick}>
          hier
        </span>
      </p>
    </div>
  ) : ladn ? (
    <div>
      Code: <p>{cdr}</p>
      <p>Lädt...</p>
    </div>
  ) : vld ? (
    <Estart />
  ) : (
    <p>Code Abgelaufen? Versuche es erneut.</p>
  );
}
