import React, { useRef, useEffect, useContext } from "react";
import firebase from "../firebase";
import AppContext from "../provider/AppContext";

export default function PayPal(props) {
  const { setPayed, setEco } = useContext(AppContext);
  const paypal = useRef(); // references Button from index.html https://www.youtube.com/watch?v=IXxEdhA7fig 8:50

  const ref = firebase.firestore();

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Zugang Spiel Tillstetten",
                amount: { currency_code: "EUR", value: 11.9 },
              },
            ],
            application_context: {
              shipping_preference: "NO_SHIPPING",
            },
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log("successfull order:", order);
          var characters = "123456789";
          var charactersLength = characters.length;
          var result = "";
          for (var i = 0; i < 9; i++) {
            result += characters.charAt(
              Math.floor(Math.random() * charactersLength)
            );
          }
          var myTimestamp = firebase.firestore.Timestamp.fromDate(
            new Date("January 1, 2000 00:00:00")
          );
          console.log(result, myTimestamp, "tmstmp");
          ref
            .collection(result)
            .doc("purchase")
            .set({
              code: result,
              email: props.eno,
              crtd: myTimestamp,
              game: "rezept",
            })
            .catch((err) => {
              console.error(err);
            })
            .then(
              ref
                .collection("coll")
                .doc(result)
                .set({
                  code: result,
                  email: props.eno,
                  crtd: myTimestamp,
                  game: "rezept",
                })
                .catch((err) => {
                  console.error(err);
                })
                .then(() => {
                  setPayed(true);
                  setEco(result);
                })
            );
        },

        onError: (err) => {
          console.log(err);
        },
      })
      .render(paypal.current);
  }, []);
  return <div ref={paypal}></div>;
}
