import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function Ansatz() {
  const navigate = useNavigate();
  function zuru() {
    navigate("/rezept");
  }

  return (
    <Fragment>
      <div className="rbckr">
        <AppBar position="static" style={{ background: "#527318" }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="arrowBack"
              sx={{ mr: 2 }}
              onClick={zuru}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              align="center"
            >
              Ermittlungsansätze
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="marginround">
          <div className="centering rcentering txthellg txtkleiner">
            <div className="abstandbar"></div>
            <p>
              Als Ausgangspunkt ist das{" "}
              <span className="abold">Protokoll der Vereinssitzung</span> gut
              geeignet. Hier bekommt ihr einen ersten Eindruck über die
              verschiedenen Interessenlagen und erste Konfliktlinien innerhalb
              der Gruppe der Eichhörnchen- und Vogelretter.
            </p>
            <p>
              Bezüglich Tilman stoßt ihr auf den roten{" "}
              <span className="abold">Flyer mit dem Demonstrationsaufruf</span>.
              Wenn ihr euch die Unterlagen durchseht, werdet ihr Informationen
              bezüglich der Tierschutzorganisation
              <span className="abold">"Jetzt! Tierschutz e.V."</span> und einen
              Zusammenhang zum Arbeitgeber von Henrik Olsens Frau Ophelia
              finden. Das solltet ihr euch genauer anschauen.
            </p>
            <p>
              Bezüglich der Paare Albert + Annika und Luna + Robin findet ihr
              auch einige Hinweise und Konfliktpotential.
            </p>
            <p>
              Informationen zur Rolle von Barbora und Jana findet ihr im
              <span className="abold">
                {" "}
                Transkript der Aussage von Beatrice
              </span>
              .
            </p>
            <p>
              Ein guter Ausgangspunkt bezüglich Henrik Olsen ist das
              <span className="abold"> Chatprotokoll</span> von seinem
              Smartphone mit Katrin.
            </p>
            <p>
              Und letztlich könnt ihr euch sicherlich weitere entscheidende
              Informationen von der Durchsuchung von Katrins Smartphone über das
              <span className="abold">
                {" "}
                Online-Portal der Polizei Tillstetten
              </span>{" "}
              versprechen.
            </p>
          </div>
        </div>
        <div className="abstanduklein"></div>
      </div>
    </Fragment>
  );
}
