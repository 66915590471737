import React, { useState, Fragment } from "react";

import { experimentalStyled as styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ESingle(props) {
  const [auf, setAuf] = useState(false);

  const ubers = [
    "Voraussetzungen Chat Jana",
    "Janas Hobbys und Alter",
    "Direkt zur Lösung",
  ];

  const hint = [
    [
      "Da Ihr bereits einen Code für das kostenpflichtige Abonnement für das Single-Portal besitzt, schließt kein neues kostenpflichtiges Abo ab, sondern klickt auf -Code eingeben- und gebt Euren Code ein. Wählt dann einen beliebigen Profilnamen (dieser spielt für Jana keine Rolle).",
      "Die Voraussetzung damit Jana mit Euch auf dem Single-Portal chattet, beschreibt Beatrice im Transkript Ihrer Aussauge. Erstellt anhand dieser Informationen ein Profil, das Jana gefällt.",
      "Jana datet nur Männer die maximal 1 Jahr älter oder jünger sind. Legt also ein Profil als Mann an, mit entsprechendem Alter.",
      "Die Hobbys die Ihr wählt, müssen genau mit denen von Jana übereinstimmen.",
      "Sucht dann in den Profilen der Singles nach Jana und drückt den Chat-Button um mit ihr zu sprechen.",
    ],
    [
      "Klettern (InfluNetz-Seite von Annika & Albert und Janas Profil auf liebesingles.de)",
      "Tennis (Transkript der Aussage von Beatrice)",
      "Architektur (Transkript der Aussage von Beatrice)",
      "Musik: Deutschrap (Flottfight Bestenliste, Janas Profil auf liebesingles.de, und die Namen die sie ihren Eichhörnchen gegeben hat.)",
      "Computer Spiele (Flottfight Bestenliste)",
      "Jana ist 1994 geboren (Flottfight Bestenliste)",
    ],
    [
      "Wenn Ihr den Chat und die Eingaben im Singleportal übrspringen wollt, bekommt ihr hier direkt die Information, die Jana für Euch hat:",
      <a
        href="https://www.liebesingles.de/loesung"
        target="liebesingles.de"
        rel="noopener noreferrer"
      >
        www.liebesingles.de/loesung
      </a>,
      "Falls Ihr technische Probleme habt, versucht Euren Code mit einem vorangestellten x, also x123456, nochmal auf einem einzigen Gerät (nicht parallel auf mehreren) aus. Wir freuen uns auch über eine kurze Beschreibung des Problems, damit wir Probleme fixen können. E-Mail an info[at]manachlux.com . Danke schon mal dafür.",
    ],
  ];

  return (
    <Fragment>
      {auf ? (
        <Grid item xs={1} sm={6} md={6}>
          <Item Item style={{ background: "#FFE75E", cursor: "pointer" }}>
            {" "}
            <p onClick={() => setAuf(!auf)}>{ubers[props.indi]}</p>
            <div className="txtdunkelgr txtkleiner">
              {hint[props.indi].map((item, index) => (
                <p>{item}</p>
              ))}
            </div>{" "}
          </Item>
        </Grid>
      ) : (
        <Grid item xs={1} sm={6} md={6} onClick={() => setAuf(!auf)}>
          <Item Item style={{ background: "#FFE75E", cursor: "pointer" }}>
            {" "}
            <p>{ubers[props.indi]}</p>
          </Item>
        </Grid>
      )}
    </Fragment>
  );
}
