import React, { Fragment } from "react";

import { useNavigate } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Estart() {
  const navigate = useNavigate();
  function zuru() {
    navigate("/");
  }

  function clickR() {
    navigate("/rezept/indizien");
  }

  function clickA() {
    navigate("/rezept/ansatz");
  }

  function clickM() {
    navigate("/rezept/motive");
  }

  function handleClick() {
    navigate("/impressum");
  }

  function clickDS() {
    navigate("/datenschutz");
  }

  return (
    <Fragment>
      <div className="rbckr">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={{ background: "#527318" }}>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="arrowBack"
                sx={{ mr: 2 }}
                onClick={zuru}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
                align="center"
              >
                Rezept zum Sterben
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="marginround">
            <div className="centering rcentering">
              <div className="abstandbar"></div>
              <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 1, sm: 16, md: 18 }}
              >
                <Grid item xs={1} sm={6} md={6} onClick={clickA}>
                  <Item style={{ background: "#FFE75E", cursor: "pointer" }}>
                    Auftrag
                  </Item>
                </Grid>
                <Grid item xs={1} sm={6} md={6} onClick={clickR}>
                  <Item style={{ background: "#FFE75E", cursor: "pointer" }}>
                    Verdächtige
                  </Item>
                </Grid>
                <Grid item xs={1} sm={6} md={6} onClick={clickM}>
                  <Item style={{ background: "#FFE75E", cursor: "pointer" }}>
                    Indizien
                  </Item>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
        <div className="abstandu"></div>
      </div>
      <div className="centerios texteins">
        {" "}
        <p>
          <span onClick={handleClick} className="impress abstandr">
            Impressum
          </span>{" "}
          <span onClick={clickDS} className="impress">
            Datenschutz
          </span>
        </p>{" "}
      </div>
    </Fragment>
  );
}
