import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

export default function Impressum() {
  const navigate = useNavigate();
  function zuru() {
    navigate("/");
  }

  return (
    <Fragment>
      <div>
        {/* <AppBar
          position="static"
          color="default"
          style={{ background: "#9ad3bc" }}
        >
          <Toolbar>
            <ArrowBackIcon onClick={handleClick} />

            <Typography variant="h6" align="center">
              Impressum
            </Typography>
          </Toolbar>
        </AppBar> */}

        <AppBar position="static" style={{ background: "#527318" }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="arrowBack"
              sx={{ mr: 2 }}
              onClick={zuru}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              align="center"
            >
              Impressum
            </Typography>
          </Toolbar>
        </AppBar>
      </div>

      <div className="marginround">
        <div className="centering wcentering">
          <p>
            Die Krimispiele "Tod in Tillstetten" und "Rezept zum Sterben" sind
            ein Produkt der Manachlux UG (haftungsbeschränkt)
          </p>{" "}
          <p>
            <a
              href="https://manachlux.com"
              target="manachlux.com"
              rel="noopener noreferrer"
            >
              www.manachlux.com
            </a>
          </p>
          <p>Besselstr. 25, 68219 Mannheim</p>
          <p>
            Eingetragen im Handelsregister - Registergericht: Mannheim,
            Registernummer HRB 733705
          </p>
          <p>Vertreten durch Geschäftsführer David Sheikh</p>{" "}
          <p>Umsatzsteuer-Identifikationsnummer: DE325044873</p>{" "}
          <p>Telefon: 0621 97688197</p>
          <p>Email: manachlux@gmail.com</p>
          <p>Verantwortlich für den Inhalt nach §55 Abs 2 RStV: David Sheikh</p>
          <p>
            Die Europäische Kommission stellt eine Online-Plattform zur
            Streitbeilegung bereit, die Sie unter{" "}
            <span>
              <a
                href="https://ec.europa.eu/consumers/odr"
                target="manachlux.com"
                rel="noopener noreferrer"
              >
                https://ec.europa.eu/consumers/odr
              </a>
            </span>{" "}
            finden. Wir sind zur Teilnahme an einem Streitbeilegungsverfahren
            vor einer Verbraucherschlichtungsstelle nicht verpflichtet und nicht
            bereit.
          </p>
        </div>{" "}
      </div>
    </Fragment>
  );
}

{
  /* <p><strong>Ihr bildet eine Sonderkommission zur Aufklärung eines Mordes.</strong> Die bisherigen polizeilichen Ermittlungen haben keinen Erfolg gebracht.</p> 
<p>Das Polizeipräsidium braucht endlich Fortschritte, und schickt euch darum den Auftrag die Ermittlungen zu übernehmen. Mit dem Auftrag erhaltet ihr die bisher gefundenen Ermittlungsergebnisse zum Fall.</p> 
<p><strong>Der Kreis der potentiellen Täter konnte schon etwas eingegrenzt werden.</strong> Nun ist eure Kreativität und kriminalistisches Gespür gefragt.</p> 
<p>Arbeitet Euch durch die bisher entdeckten Beweise und Indizien, stellt Recherchen an.</p> 
<p><strong>Haben Verdächtige Alibis und kommen für die Tat nicht in Frage? Wer ist der oder die Täterin?</strong></p> 
<p>Untersucht die verschiedenen Hinweise, diskutiert in der Gruppe welche Motive die Verdächtigen gehabt haben könnten einen Mord zu begehen. Wer hat etwas zu verbergen?</p> 
<p>Am besten besteht eure Sonderkommission aus 2-7 Spielern um gemeinsam zu ermitteln. Das Spiel lässt sich aber auch solo spielen.</p> 
<p><strong>Für eure Ermittlungsarbeit ist ein Internetzugang erforderlich, da ihr bei einer Online-Recherche auf Hinweise stoßen werdet, die ihr benötigt, um den Täter/die Täterin zu finden.</strong></p> */
}
