import React, { Fragment, useContext } from "react";
import AppContext from "../provider/AppContext";
import { useNavigate } from "react-router-dom";

// import firebase from "../firebase";

export default function ZahlBest() {
  const { ant, eco } = useContext(AppContext);
  const navigate = useNavigate();
  //  const [erg, setErg] = useState({});
  //  const [loading, setLoading] = useState(true);

  // const ref = firebase.firestore();

  // function getSchools2() {
  //   ref
  //     .collection(eco)
  //     .doc("purchase")
  //     .get()
  //     .then((doc) => {
  //       if (doc.exists) {
  //         setErg(doc.data());
  //       } else {
  //         console.log("doc not found");
  //       }
  //       setLoading(false);
  //       console.log(erg, doc.data(), "erg", eco);
  //     });
  // }
  // useEffect(() => {
  //   getSchools2();
  //   // eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   const unsubscribe = ref
  //     .collection(eco)
  //     .doc("purchase")
  //     .onSnapshot(function (doc) {
  //       setErg(doc.data());
  //       console.log(doc.data(), "doc.data().active");
  //     });

  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  function weitnav() {
    navigate("/ermittlung");
  }

  return (
    <Fragment>
      <div>
        <p>
          Du hast bezahlt!! Dein Code ist <span className="abold">{eco}</span>
        </p>
        <p>
          Du erhälst in den nächsten 12 Stunden eine E-Mail (Absender Manachlux)
          an <span className="abold"> {ant}</span> mit der Betätigung/Rechnung
          Deines Auftrags.
        </p>
        <button onClick={weitnav}>Weiter</button>
      </div>
    </Fragment>
  );
}
