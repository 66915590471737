import React, { useState, useContext } from "react";
import AppContext from "../provider/AppContext";

import Bewitext from "./Bewitext";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import fingerabt from "../thumbs/fingerabt.jpg";
import kartet from "../thumbs/kartet.jpg";
import zeitung1t from "../thumbs/zeitung1t.jpg";
import zeitung2t from "../thumbs/zeitung2t.jpg";
import traueranzt from "../thumbs/traueranzt.jpg";
import briefanwaltt from "../thumbs/briefanwaltt.jpg";
import chat1t from "../thumbs/chat1t.jpg";
import chat2t from "../thumbs/chat2t.jpg";
import chat3t from "../thumbs/chat3t.jpg";
import buechereit from "../thumbs/buechereit.jpg";
import briefmichat from "../thumbs/briefmichat.jpg";
import zertifikatt from "../thumbs/zertifikatt.jpg";
import gutacht from "../thumbs/gutacht.jpg";
import aut from "../thumbs/aut.jpg";
import hautwt from "../thumbs/hautwt.jpg";
import fragent from "../thumbs/fragent.jpg";
import herzfret from "../thumbs/herzfret.jpg";
import knoellt from "../thumbs/knoellt.jpg";
import anonymt from "../thumbs/anonymt.jpg";
import fingert1t from "../thumbs/fingert1t.jpg";
import fingert2t from "../thumbs/fingert2t.jpg";
import liefert from "../thumbs/liefert.jpg";
import buecherit from "../thumbs/buecherit.jpg";
import bewertt from "../thumbs/bewertt.jpg";
import highscorert from "../thumbs/highscorert.jpg";
import fundt from "../thumbs/fundt.jpg";
import besuchert from "../thumbs/besuchert.jpg";
import wukifediat from "../thumbs/wukifediat.jpg";
import simont from "../thumbs/simont.jpg";
import pakett from "../thumbs/pakett.jpg";
import vereint from "../thumbs/vereint.jpg";

import fingerabd from "../bewi/a5ManachluxV2.jpg";
import karte from "../bewi/a5MKarte.jpg";
import zeitung1 from "../bewi/zeitung1.jpg";
import zeitung2 from "../bewi/zeitung2.jpg";
import zodesanzeige from "../bewi/zodesanzeige.jpg";
import anwaltsbrief from "../bewi/anwaltsbrief.jpg";
import chat1 from "../bewi/chat1.jpg";
import chat2 from "../bewi/chat2.jpg";
import chat3 from "../bewi/chat3.jpg";
import buecheraus from "../bewi/a6Manachlux.jpg";
import briefmichaela from "../bewi/briefmichaela.jpg";
import zertifikato from "../bewi/zertifikato.jpg";
import pferdegutachter from "../bewi/pferdegutachter.jpg";
import aufertigglb from "../bewi/aufertigglb.jpg";
import luegend1 from "../bewi/luegend1.jpg";
import luegend2 from "../bewi/luegend2.jpg";
import luegend3 from "../bewi/luegend3.jpg";
import knoellchen from "../bewi/knoellchen.jpg";
import anobrief from "../bewi/anobrief.jpg";
import fingerano1 from "../bewi/fingerano1.jpg";
import fingerano2 from "../bewi/fingerano2.jpg";
import lieferbit from "../bewi/lieferbit.jpg";
import belegbibliothek from "../bewi/belegbibliothek.jpg";
import rezession from "../bewi/rezession.jpg";
import highc from "../bewi/highc.jpg";
import fundbuero from "../bewi/fundbuero.jpg";
import jva from "../bewi/jva.jpg";
import wukifediao from "../bewi/wukifediao.jpg";
import simonmail from "../bewi/simonmail.jpg";
import paketzustellung from "../bewi/paketzustellung.jpg";
import verein from "../bewi/verein.jpg";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//   },
// }))

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const thumbi = [
  { bild: fingerabt, alt: "Fingerabdrücke" },
  { bild: kartet, alt: "Karte" },
  { bild: zeitung1t, alt: "Zeitung 1" },
  { bild: zeitung2t, alt: "Zeitung 2" },
  { bild: traueranzt, alt: "Traueranzeige" },
  { bild: briefanwaltt, alt: "Brief Anwalt" },
  { bild: chat1t, alt: "Chat 1" },
  { bild: chat2t, alt: "Chat 2" },
  { bild: chat3t, alt: "Chat 3" },
  { bild: buechereit, alt: "Büchereiausweis" },
  { bild: briefmichat, alt: "Brief von Michaela" },
  { bild: zertifikatt, alt: "Zertifikat" },
  { bild: gutacht, alt: "Brief Pferdegutachter" },
  { bild: aut, alt: "Krankschreibung" },
  { bild: hautwt, alt: "Messung Hautwiderstand" },
  { bild: fragent, alt: "Fragen Lügendetektor" },
  { bild: herzfret, alt: "Messung Herzfrequenz" },
  { bild: knoellt, alt: "Knöllchen" },
  { bild: anonymt, alt: "Anonymer Brief" },
  { bild: fingert1t, alt: "Vergrößerung Fingerabdruck 1" },
  { bild: fingert2t, alt: "Vergrößerung Fingerabdruck 2" },
  { bild: liefert, alt: "Bestellungen Lieferbit" },
  { bild: buecherit, alt: "Beleg Bücherei" },
  { bild: bewertt, alt: "Bewertungen" },
  { bild: highscorert, alt: "Highscorer" },
  { bild: fundt, alt: "Fundbüroabholungen" },
  { bild: besuchert, alt: "Besucherrichtlinien JVA" },
  { bild: wukifediat, alt: "Online-Lexikon Wukifedia" },
  { bild: simont, alt: "Simon Käufer Email" },
  { bild: pakett, alt: "Paketbeleg" },
  { bild: vereint, alt: "Vereinssitzung" },
];

const bewi = [
  { bild: fingerabd, alt: "Fingerabdrücke" },
  { bild: karte, alt: "Karte" },
  { bild: zeitung1, alt: "Zeitung 1" },
  { bild: zeitung2, alt: "Zeitung 2" },
  { bild: zodesanzeige, alt: "Traueranzeige" },
  { bild: anwaltsbrief, alt: "Brief Anwalt" },
  { bild: chat1, alt: "Chat 1" },
  { bild: chat2, alt: "Chat 2" },
  { bild: chat3, alt: "Chat 3" },
  { bild: buecheraus, alt: "Büchereiausweis" },
  { bild: briefmichaela, alt: "Brief von Michaela" },
  { bild: zertifikato, alt: "Zertifikat" },
  { bild: pferdegutachter, alt: "Brief Pferdegutachter" },
  { bild: aufertigglb, alt: "Krankschreibung" },
  { bild: luegend1, alt: "Messung Hautwiderstand" },
  { bild: luegend2, alt: "Fragen Lügendetektor" },
  { bild: luegend3, alt: "Messung Herzfrequenz" },
  { bild: knoellchen, alt: "Knöllchen" },
  { bild: anobrief, alt: "Anonymer Brief" },
  { bild: fingerano1, alt: "Vergrößerung Fingerabdruck 1" },
  { bild: fingerano2, alt: "Vergrößerung Fingerabdruck 2" },
  { bild: lieferbit, alt: "Bestellungen Lieferbit" },
  { bild: belegbibliothek, alt: "Beleg Bücherei" },
  { bild: rezession, alt: "Bewertungen" },
  { bild: highc, alt: "Highscorer" },
  { bild: fundbuero, alt: "Fundbüroabholungen" },
  { bild: jva, alt: "Besucherrichtlinien JVA" },
  { bild: wukifediao, alt: "Online-Lexikon Wukifedia" },
  { bild: simonmail, alt: "Simon Käufer Email" },
  { bild: paketzustellung, alt: "Paketbeleg" },
  { bild: verein, alt: "Vereinssitzung" },
];

export default function Beweise() {
  const { setAuf } = useContext(AppContext);
  const [indi, setIndi] = useState(0);

  function handleClick(index) {
    setIndi(index);
    setAuf(false);
  }
  return (
    <div className="centering wcentering">
      <div>
        <img className="tatv" src={bewi[indi].bild} alt={bewi[indi].alt} />
        <Bewitext indb={indi} />
      </div>

      <div className="abstandl"></div>

      <div>
        <Grid container spacing={3}>
          {thumbi.map((school, index) => (
            <Grid item xs={3}>
              <img
                className="tatv"
                src={school.bild}
                alt={school.alt}
                onClick={() => handleClick(index)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}
