import React from "react";
import alex from "../bilder/alexkau.jpg";

export default function Auftrag() {


    let date = new Date();
    return (

        <div className="centering wcentering">
            <p>
                {`${date.getDate()}`}.{`${date.getMonth() + 1}`}.{`${date.getFullYear()}`}, 08:32 Uhr</p>
            <hr />
            <p className="aadresse abold"><span>An: Stabstelle Sonderkommission (SOKO) Tillstetten</span><br />
                <span>Von: Polizeipräsidium</span></p>
            <h3>Betreff: AUFTRAG -- Sonderkommission Tillstetten</h3>
            <hr />





            <p>Sehr geehrte Kolleginnen und Kollegen,</p>

            <p>wie Sie durch die Presse sicherlich mitbekommen haben, gab es am 02.02.2021 einen Mordfall in Tillstetten. Die bisherigen Ermittlungen verlaufen ohne konkrete Ergebnisse. Der Druck der Öffentlichkeit, den Täter oder die Täterin zu fassen ist groß. Bei der Durchsicht der bisher zusammen getragenen Indizien, erscheint mir die bisherige Polizeiarbeit nicht besonders gründlich.
</p>

            <p className="aunderline">Daher berufe ich nun Sie als Sonderkommission (SOKO). Sie übernehmen die Ermittlungen.
</p>

            <p className="aunderline abold">Zum Fall:</p>

            <p>Das Opfer ist der 53 Jahre alte Alexander Käufer. Er war der Besitzer der „AlexKäuf Werkstätten GmbH“, einer Kette von Autowerkstätten.
</p>

            <p>Er war kurz vor seinem Tod aus seinem Einfamilienhaus, das er zu zweit mit seiner Ehefrau bewohnt hatte ausgezogen.
</p>
            <img src={alex} alt="Alexander Käufer" />
            <span className="aadresse">Foto des Getöteten Alexander Käufer</span>
            <p>Mit seiner neuen Freundin, einer Betriebswirtschaftsstudentin ist er in ein ca. 5 km entferntes Haus gezogen. Vor dem neuen Wohnhaus wurde er am 02.02.2021 um 08:25 Uhr erschlagen. Er war scheinbar gerade auf dem Weg zu seinem Auto. Es gibt keine Zeugen.
</p>

            <p>Der Todeszeitpunkt lässt sich so gut eingrenzen, weil er bis 08:23 Uhr einen Anruf von jemandem mit unterdrückter Rufnummer entgegengenommen, und um 08:28 Uhr die Leiche von einer Spaziergängerin entdeckt wurde.
</p>

            <p>Die Tatwaffe wurde am Tatort gefunden. Es handelt sich um einen Golfschläger aus dem Golfset „Greenschoen“. An der Tatwaffe konnten keine verwertbaren Spuren gefunden werden. Das Golfset „Greenschoen“ ist ausschließlich über den Online-Shop „LieferBit“ bestellbar. Bei diesem Online-Shop können die Kunden auf Wunsch anonym mit der Kryptowährung Bitcoin bezahlen, und an eine Packstation liefern lassen kann. Dies trifft auf 9 Bestellungen zu. Alle nicht-anonymen Käufer konnten als Täter ausgeschlossen werden. Das Golfset ist seit 01.01.2021 erhältlich.
</p>

            <p>Wenigstens konnten die bisherigen Ermittler den Kreis der Tatverdächtigen zuverlässig eingrenzen. Alle bisher gefundenen Indizien und Beweise schicke ich Ihnen in diesem Umschlag.
</p>


            <p>Noch etwas: Die bisherigen Ermittler haben es nicht geschafft, Zugang zu den Emails des Getöteten bei seinem Mailprovider (www.gumblmail.de) zu bekommen. Es fehlt an Know-how, um die Emails zu hacken. Wir hoffen hier auf Ihre Fähigkeiten. Denn Zugang zu den Emails von Alexander Käufer könnte die Ermittlungen weiterbringen.
</p>

            <p>Viel Erfolg!</p>

            <p>Arndt Weinhalt</p>




        </div>
    );
}