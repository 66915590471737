import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Kai from "../components/crezept/Kai";
import LFrage from "../components/crezept/LFrage";

export default function RLoesung() {
  const [antwort, setAntwort] = useState("");
  const [valida, setValida] = useState("");
  const [atw, setAtw] = useState([]);
  const [zeig, setZeig] = useState(false);

  const register = () => {
    if (
      antwort === "Henrik" ||
      antwort === "henrik" ||
      antwort === "Hendrik" ||
      antwort === "hendrik"
    ) {
      setValida("");
      setAtw([
        "Selbstmord? Nein. Ein selbstverschuldetes Versehen? Dafür gibt es auch keine Anhaltspunkte.",
      ]);
    } else if (antwort === "Robin" || antwort === "robin") {
      setValida("");
      setAtw([
        "Robin wusste, dass Henrik seine Diät beendet hatte, und er hätte ein Motiv. Allerdings hat Robin nicht gewusst, dass einer der Wraps vergiftet ist. Robin ist nicht der Täter.",
      ]);
    } else if (antwort === "Luna" || antwort === "luna") {
      setValida("");
      setAtw([
        "Luna wusste, dass Henrik seine Diät beendet hatte, und sie hätte ein Motiv. Allerdings hat Luna nicht gewusst, dass einer der Wraps vergiftet ist. Luna ist nicht die Täterin.",
      ]);
    } else if (
      antwort === "Tilman" ||
      antwort === "tilman" ||
      antwort === "Tillman" ||
      antwort === "tillman" ||
      antwort === "Tilmann" ||
      antwort === "tilmann" ||
      antwort === "Tillmann" ||
      antwort === "tillmann"
    ) {
      setValida("");
      setAtw([
        "Tilman konnte nicht wissen, dass Henrik seine Diät beendet hatte und er wusste nicht, dass einer der Wraps vergiftet war. Außerdem hat Tilman kein Motiv. Er ist nicht der Täter.",
      ]);
    } else if (
      antwort === "Ophelia" ||
      antwort === "ophelia" ||
      antwort === "Ofelia" ||
      antwort === "ofelia"
    ) {
      setValida("");
      setAtw([
        "Ophelia hat ein Alibi. Sie war am Abend als Henrik vergiftet wurde nicht anwesend, weil sie erst nachts von der Arbeit kam.",
      ]);
    } else if (
      antwort === "Katrin" ||
      antwort === "katrin" ||
      antwort === "Kathrin" ||
      antwort === "kathrin"
    ) {
      setValida("");
      setAtw([
        "Katrin hat versucht Henriks Hund zu vergiften. Da die Flaggen auf den Wraps aber vertauscht wurden, hat der Hund nur einen unvergifteten Wrap von Katrin bekommen. Da die Flaggen auf den Wraps vom Täter absichtlich vertauscht wurden, ist Katrin auch nicht versehentlich Schuld an Henriks Vergiftung. Auch das Verhältnis mit Lars spricht dafür, dass Katrin den Hund vergiften wollte, und nicht Henrik, zu dem sie ein freundschaftliches Verhältnis hat.",
      ]);
    } else if (
      antwort === "Kai" ||
      antwort === "kai" ||
      antwort === "Kei" ||
      antwort === "kei" ||
      antwort === "Key" ||
      antwort === "key"
    ) {
      setValida("");
      setAtw([<Kai />]);
    } else if (antwort === "Barbora" || antwort === "barbora") {
      setValida("");
      setAtw([
        "Barbora hatte keinen Grund Henrik zu vergiften. Zudem hat sie möglicherweise auch einen Wrap gegessen. Ein riskantes Verhalten, wenn sie gewusst hätte, dass ein Wrap vergiftet ist. Sie ist nicht die Täterin",
      ]);
    } else if (antwort === "Albert" || antwort === "albert") {
      setValida("");
      setAtw([
        "Albert hätte ein Motiv gehabt, da er als potentieller Besitzer ener Außenvoliere besseren Zugang zu Eichhörnchen für sein Social-Media-Profil gehabt hätte wenn er und Annika Henrik ersetzen. Allerdings wusste er weder, dass einer der Wraps vergiftet war, noch dass Henrik seine Diät beendet hatte. Er ist nicht der Täter",
      ]);
    } else if (
      antwort === "Annika" ||
      antwort === "annika" ||
      antwort === "Anika" ||
      antwort === "anika"
    ) {
      setValida("");
      setAtw([
        "Annika hätte ein Motiv gehabt, da sie als potentielle Besitzerin ener Außenvoliere besseren Zugang zu Eichhörnchen für ihr Social-Media-Profil gehabt hätte wenn sie und Albert Henrik ersetzen. Allerdings wusste sie weder, dass einer der Wraps vergiftet war, noch dass Henrik seine Diät beendet hatte. Sie ist nicht die Täterin",
      ]);
    } else if (antwort === "Jana" || antwort === "jana") {
      setValida("");
      setAtw([
        "Jana hat die Wraps zubereitet, und einen davon absichtlich vergiftet. Es wäre rafiniert von ihr, wenn sie Katrin vorgespielt hätte, dass sie nur den Hund vergiften will, tatsächlich aber das Dänemark-Fähnchen auf dem vergifteten Wrap platziert, um Henrik zu töten und es wie einen Unfall aussehen zu lassen. Allerdings gibt es auf den Wraps wie Jana sie mitgebracht hat (Foto 18:54 Uhr) gar keine Dänemark-Flagge. Es gibt für Jana keinen Grund, die Wraps in Henriks Haus zu bringen und dann erst die Fähnchen zu tauschen. Zudem weiß Jana nicht, dass Henrik seine Diät nicht mehr macht. Es ist sogar anzunehmen, dass Jana extra deshalb Wraps mit Feta/Schafskäse gemacht hat, weil sie weiß, dass die meisten Gäste wegen Unverträglichkeiten und Diäten davon nichts essen würden. Jana ist nicht die Täterin.",
      ]);
    } else if (
      antwort === "Beatrice" ||
      antwort === "beatrice" ||
      antwort === "Beatriz" ||
      antwort === "beatriz" ||
      antwort === "Beatrise" ||
      antwort === "beatrise"
    ) {
      setValida("");
      setAtw([
        "Beatrice hatte kein Wissen über den vergifteten Wrap und auch kein Motiv. Beatrice ist nicht die Täterin",
      ]);
    } else if (antwort === "Lars" || antwort === "lars") {
      setValida("");
      setAtw([
        "Lars war an dem Abend nicht eingeladen. Da er nicht zur Gruppe gehört, wäre es ihm auch nicht möglich gewesen so einen rafinierten Plan auszuführen. Zudem hätte ihm wohl der ursprüngliche Plan den Hund zu vergiften, besser gefallen. Lars ist nicht der Täter.",
      ]);
    } else if (antwort === "") {
      setValida("");
      setAtw("");
    } else {
      setValida(
        "Upps, etwas ist schiefgelaufen. Ist die Schreibweise korrekt? Gebt bitte nur den Vornamen ohne Leerzeichen ein."
      );
      setAtw([""]);
    }
  };

  const navigate = useNavigate();
  function zuru() {
    navigate("/rezept");
  }

  return (
    <Fragment>
      <div className="rbckr">
        <AppBar position="static" style={{ background: "#527318" }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="arrowBack"
              sx={{ mr: 2 }}
              onClick={zuru}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              align="center"
            >
              Lösung
            </Typography>
          </Toolbar>
        </AppBar>

        <div className="marginround">
          <div className="centering wcentering anlei">
            {" "}
            {zeig ? (
              <div>
                <p>
                  Gebt hier den Vornamen des Täters oder der Täterin ein, um
                  herauszufinden, ob Ihr richtig liegt.
                </p>
                <div>
                  <input
                    className="inputb ten-margin antwort"
                    type="text"
                    value={antwort}
                    onChange={(e) => setAntwort(e.target.value)}
                    placeholder="Vorname Täter/in"
                  />{" "}
                  <button className="importbutton" onClick={register}>
                    Absenden
                  </button>
                  <p className="valii">{valida}</p>
                  <p className="txthellg">{atw[0]}</p>
                  <div className="luecke"></div>
                </div>
              </div>
            ) : (
              <div>
                <LFrage />
                <button className="importbutton" onClick={() => setZeig(!zeig)}>
                  Weiter zur Lösung
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="abstandu"></div>
      </div>
    </Fragment>
  );
}
