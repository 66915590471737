import React, { useState, useEffect, useContext } from "react";

import { useNavigate } from "react-router-dom";
import firebase from "../firebase";
import AppContext from "../provider/AppContext";

export default function Code() {
  const { reg, setReg } = useContext(AppContext);
  const ref = firebase.firestore().collection("coll");

  const [email, setEmail] = useState("start");
  const [valida, setValida] = useState("");

  const [bpressed, setBpressed] = useState(false);

  const navigate = useNavigate();
  function handleClick() {
    navigate("/app/tillstetten");
  }

  const date = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);
  let timestamp;

  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //     console.log("useEffect running!!");
  //     console.log(email);
  //     if (email !== "") {
  //         ref.doc(email)
  //             .get()
  //             .then(function (doc) {
  //                 if (doc.exists) {
  //                     console.log("fillee exists");
  //                     setCodeDate(doc.data().crtd);
  //                     console.log(codeDate.toDate() > date);
  //                     let hours = Math.abs(codeDate.toDate() - date) / 36e5;
  //                     console.log("abstand in Stunden", hours);
  //                     setValida("");
  //                     setLoading(false);
  //                     if (codeDate.toDate() < date) { setReg(true) } else { setValida("Der Code ist abgelaufen. In der App erzeugte Codes für Freunde sind nur 48 Stunden gültig."); }
  //                 } else {
  //                     console.log("No such document userlog!");
  //                     email !== "start" ? setValida(
  //                         "Ungültiger Code. Überprüfe die Schreibweise, und achte darauf z.B. alle Leerzeichen zu entfernen."
  //                     ) : console.log("initial run");
  //                     setLoading(false);
  //                 }
  //             })
  //             .catch(function (error) {
  //                 console.log("Error getting document userlog:", error);
  //                 setValida(
  //                     "Upps, es gab ein Problem. Das Überprüfen des Codes hat nicht funktioniert nicht. Versuche es bitte später nochmal."
  //                 );
  //                 setLoading(false);
  //             });
  //     } else {
  //         setEmail("empty");
  //         console.log("email is empty!");
  //         setValida("");
  //         setLoading(false);
  //     }

  // }, [bpressed]);

  let codeDate;
  const register = () => {
    // console.log(email, "in register");
    // setBpressed(!bpressed)
    console.log("useEffect running!!");
    console.log(email);
    if (email !== "") {
      ref
        .doc(email)
        .get()
        .then(function (doc) {
          if (doc.exists) {
            console.log("fillee exists", doc.data().crtd);
            codeDate = doc.data().crtd;
            console.log(codeDate.toDate() > date);
            let hours = Math.abs(codeDate.toDate() - date) / 36e5;
            console.log("codeDate", codeDate);
            console.log("abstand in Stunden", hours);
            setValida("");
            setLoading(false);
            if (codeDate.toDate() > date) {
              setReg(true);
              console.log("Code gültig!");
            } else {
              setValida(
                "Der Code ist abgelaufen. In der App erzeugte Codes für Freunde sind nur 48 Stunden gültig."
              );
            }
          } else {
            console.log("No such document userlog!");
            email !== "start"
              ? setValida(
                  "Ungültiger Code. Überprüfe die Schreibweise, und achte darauf z.B. alle Leerzeichen zu entfernen."
                )
              : console.log("initial run");
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log("Error getting document userlog:", error);
          setValida(
            "Upps, es gab ein Problem. Das Überprüfen des Codes hat nicht funktioniert nicht. Versuche es bitte später nochmal."
          );
          setLoading(false);
        });
    } else {
      setEmail("empty");
      console.log("email is empty!");
      setValida("");
      setLoading(false);
    }
  };

  return reg ? (
    <div className="centerix centerios abstando">
      <button className="butto" onClick={handleClick}>
        Zum Fall
      </button>
    </div>
  ) : (
    <div className="centerix centerios abstando">
      <input
        className="inputb"
        type="number"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Code"
      />{" "}
      <br />
      <p className="valida">{valida}</p>
      <button className="butto" onClick={register}>
        Absenden
      </button>
    </div>
  );
}
