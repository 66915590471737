import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import bianca from "../bilder/bianca.jpg";
import carolin from "../bilder/carolin.jpg";
import hans from "../bilder/hans.jpg";
import heike from "../bilder/heike.jpg";
import jana from "../bilder/jana.jpg";
import lilly from "../bilder/lilly.jpg";
import ludwig from "../bilder/ludwig.jpg";
import markus from "../bilder/markus.jpg";
import michaela from "../bilder/michaela.jpg";
import nico from "../bilder/nico.jpg";
import simon from "../bilder/simon.jpg";
import theresa from "../bilder/theresa.jpg";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//     },
//     paper: {
//         padding: theme.spacing(2),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//     },
// }));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const thumbi = [
  { bild: carolin, alt: "Carolin Käufer" },
  { bild: simon, alt: "Simon Käufer" },
  { bild: theresa, alt: "Theresa Mink" },
  { bild: michaela, alt: "Michaela Maurer" },
  { bild: markus, alt: "Markus Mink" },
  { bild: nico, alt: "Geschäftsführer TillSt Autoteile GmbH" },
  { bild: bianca, alt: "Neue Partnerin von Alexander Käufer" },
  { bild: hans, alt: "Hans Hoppe" },
  { bild: heike, alt: "Heike Hoppe" },
  { bild: ludwig, alt: "Ludwig Liebig" },
  { bild: lilly, alt: "Lilly Liebig" },
  { bild: jana, alt: "Jana Jäckel" },
];

export default function Tatverd() {
  const [indi, setIndi] = useState(0);

  return (
    <div className="centering wcentering">
      <div>
        <img className="tatv" src={thumbi[indi].bild} alt={thumbi[indi].alt} />
      </div>

      <span className="centerios">{thumbi[indi].alt}</span>
      <div className="abstandl"></div>

      <div>
        <Grid container spacing={3}>
          {thumbi.map((school, index) => (
            <Grid item xs={3}>
              <img
                className="tatv"
                src={school.bild}
                alt={school.alt}
                onClick={() => setIndi(index)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}
