import React, { Fragment, useState, useContext } from "react";
import AppContext from "../provider/AppContext";
import CodeR from "../components/CodeR";
import MailZahl from "../components/MailZahl";
import ZahlBest from "../components/ZahlBest";

export default function Krimispiel() {
  const { payed, erm } = useContext(AppContext);
  const [kaufoffen, setKaufoffen] = useState(false);

  const displayThis = payed ? (
    //  const displayThis = true ? (
    <div>
      <ZahlBest />
    </div>
  ) : (
    <div>
      <MailZahl />
    </div>
  );

  const kaufdisplay = kaufoffen ? (
    <div className="centering wcentering">
      <p onClick={() => setKaufoffen(!kaufoffen)}>Hier gehts zum Kauf</p>
      <div>{displayThis}</div>
    </div>
  ) : (
    <div className="centering wcentering">
      <p onClick={() => setKaufoffen(!kaufoffen)}>Hier gehts zum Kauf</p>
    </div>
  );

  return (
    <Fragment>
      <div>
        <p>Hier steht Werbung und Fotos zum Spiel</p>
      </div>
      {erm === false ? <div>{kaufdisplay}</div> : null}
      <div className="centering wcentering">
        {erm === false ? <p>Code eingeben</p> : null}
        <CodeR />
      </div>
    </Fragment>
  );
}
