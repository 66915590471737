import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import buecherbeleg from "../thumbs/buecherit.jpg";
import traueranzt from "../thumbs/traueranzt.jpg";
import liefert from "../thumbs/liefert.jpg";
import fingerabt from "../thumbs/fingerabt.jpg";
import highscorert from "../thumbs/highscorert.jpg";
import aut from "../thumbs/aut.jpg";
import simont from "../thumbs/simont.jpg";
import chat1t from "../thumbs/chat1t.jpg";
import chat2t from "../thumbs/chat2t.jpg";
import hautwt from "../thumbs/hautwt.jpg";
import fundt from "../thumbs/fundt.jpg";
import gutacht from "../thumbs/gutacht.jpg";
import besuchert from "../thumbs/besuchert.jpg";
import briefanwaltt from "../thumbs/briefanwaltt.jpg";
import briefmichat from "../thumbs/briefmichat.jpg";
import pakett from "../thumbs/pakett.jpg";
import knoellt from "../thumbs/knoellt.jpg";
import wukifediat from "../thumbs/wukifediat.jpg";
import bewertt from "../thumbs/bewertt.jpg";
import vereint from "../thumbs/vereint.jpg";
import emailt from "../thumbs/emailt.jpg";
import zertifikatt from "../thumbs/zertifikatt.jpg";
import webst from "../thumbs/webst.jpg";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//     },
//     paper: {
//         padding: theme.spacing(0),
//         textAlign: 'left',
//         color: theme.palette.text.secondary,
//     },
// }));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export default function Hinweis(props) {
  const thumbi = [
    buecherbeleg,
    traueranzt,
    liefert,
    fingerabt,
    highscorert,
    aut,
    simont,
    chat1t,
    chat2t,
    hautwt,
    fundt,
    gutacht,
    besuchert,
    briefanwaltt,
    briefmichat,
    pakett,
    knoellt,
    wukifediat,
    bewertt,
    vereint,
    zertifikatt,
    emailt,
    emailt,
    emailt,
    emailt,
    emailt,
    emailt,
    webst,
    webst,
    webst,
  ];
  const [auf, setAuf] = useState(false);

  return (
    <div>
      <Grid container spacing={1} onClick={() => setAuf(!auf)}>
        <Grid item xs={3}>
          <img className="thumb" src={thumbi[props.index]} alt="thumbnail" />
        </Grid>
        <Grid item xs>
          <span>{props.hint[0]}</span>
        </Grid>
      </Grid>
      {auf ? (
        <div className="hinwifarbe">
          <p>{props.hint[1]}</p>
          <p>{props.hint[2]}</p>
        </div>
      ) : null}
      <hr />
    </div>
  );
}
