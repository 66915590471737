import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function Datenschutz() {
  const navigate = useNavigate();
  function zuru() {
    navigate("/");
  }
  return (
    <Fragment>
      <div className="rbckr">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={{ background: "#527318" }}>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="arrowBack"
                sx={{ mr: 2 }}
                onClick={zuru}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
                align="center"
              >
                Datenschutz
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
        <div className="marginround">
          <div className="centering rcentering">
            <div className="abstandbar"></div>

            <p>Speicherung persönlicher Daten</p>
            <p>
              Daten die Sie uns zur Verfügung stellen etwa wenn sie uns über
              Telefon oder E-Mail kontaktieren, werden sicher verwahrt, und nur
              für den unmittelbaren Zweck Ihrer Anfrage bearbeitet. Diese Daten
              werden nicht an Dritte weitergegeben (außer wir sind etwa bei
              Rechtsverstößen Behörden gegenüber zur Auskunft verpflichtet).
            </p>
            <p>
              Wir nutzen diese Daten zur Abwicklung der auf dieser Website
              angebotenen Dienstleistung.
            </p>
            <p>
              Wenn Sie die vollständige Löschung Ihrer Daten wünschen, reicht
              eine formlose E-Mail an info[at]manachlux.com
            </p>
            <p>
              Grundsätzlich haben Sie folgende Rechte: Recht auf Berichtigung,
              Recht auf Löschung, Recht auf Einschränkung der Verarbeitung,
              Recht auf Benachrichtigung, Recht auf Datenübertragbarkeit,
              Widerspruchsrecht, und das Recht nicht von Profiling betroffen zu
              sein (Artikel 16 - 22 DSGVO).
            </p>
            <p>
              Auf dieser Website wird Google Fonts verwendet. Deshalb speichert
              Google Font ist nach Angaben von Google vollkommen getrennt von
              anderen Google Diensten, laut Google werden personenbezogene Daten
              dabei nicht verarbeitet oder erfasst. Google Font dateien werden
              auf Ihrem Rechner gespeichert um die Ladezeiten von Webseiten zu
              verringern.
            </p>
            <p></p>
          </div>
          <div className="abstanduklein"></div>
        </div>{" "}
      </div>
    </Fragment>
  );
}
