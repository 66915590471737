import React, { Fragment } from "react";
import Start from "../components/Start";

export default function Main() {
  return (
    <Fragment>
      <Start />
    </Fragment>
  );
}
