import React, { useState, Fragment } from "react";

import { experimentalStyled as styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import demo from "../../rthumbs/demo.jpg";
import auftrag from "../../rthumbs/auftrag.jpg";
import beleg from "../../rthumbs/beleg.jpg";
import chat from "../../rthumbs/chat.jpg";
import eichiliste from "../../rthumbs/eichiliste.jpg";
import essen from "../../rthumbs/essen.jpg";
import essliste from "../../rthumbs/essliste.jpg";
import high from "../../rthumbs/high.jpg";
import influ from "../../rthumbs/influ.jpg";
import inflver from "../../rthumbs/inflver.jpg";
import karriere from "../../rthumbs/karriere.jpg";
import karte from "../../rthumbs/karte.jpg";
import keto from "../../rthumbs/keto.jpg";
import kostenvor from "../../rthumbs/kostenvor.jpg";
import lars from "../../rthumbs/lars.jpg";
import mailsuper from "../../rthumbs/mailsuper.jpg";
import pestozut from "../../rthumbs/pestozut.jpg";
import protokoll from "../../rthumbs/protokoll.jpg";
import rundschr from "../../rthumbs/rundschr.jpg";
import smart from "../../rthumbs/smart.jpg";
import transkript from "../../rthumbs/transkript.jpg";
import veganapfel from "../../rthumbs/veganapfel.jpg";
import zeitung from "../../rthumbs/zeitung.jpg";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Einzelindiz(props) {
  const thumbr = [
    demo,
    auftrag,
    beleg,
    chat,
    eichiliste,
    essen,
    essliste,
    high,
    influ,
    inflver,
    karriere,
    karte,
    keto,
    kostenvor,
    lars,
    mailsuper,
    pestozut,
    protokoll,
    rundschr,
    smart,
    transkript,
    veganapfel,
    zeitung,
  ];

  const ubers = [
    "Demoaufruf",
    "Auftrag Sonderkommission",
    "Kassenbeleg",
    "Chat Henrik - Katrin",
    "Tabellen Tierverteilung",
    "Fotos Essen",
    "Liste Essen Mitbringen",
    "Flottfight Bestenliste",
    "Influnetzprofile",
    "Vertrag Influencer",
    "KarriereNetz Profil",
    "Dankkarte",
    "Ketogene Diät",
    "Kostenvoranschlag",
    "Anzeige Ruhestörung",
    "E-Mail Supermarkt",
    "Rezept Pesto-Wraps",
    "Protokoll Vereinssitzung",
    "Rundmail Hulei-chem",
    "Schlafzeiten Smartwatch",
    "Transkript Aussage Beatrice",
    "Rezept Apfelkuchen",
    "Zeitschriftenartikel Ophelia Olsen",
  ];

  const hint = [
    [
      "Wichtig ist hier der Hinweis auf den Organisator des Fahrdienstes.",
      "Tilman engagiert sich also für die Organisation, die auch das Firmeninterne Material von Hulei-chem veröffentlicht hat.",
    ],
    [
      "Ophelia kann Henrik nicht vergiftet haben, da sie am Abend der Vergiftung erst um 01:00 nachts nach Hause kam.",
      "Es gibt einen anonymen Hinweis gegen Katrin. Jemand hat ein Interesse daran, dass sie als Täterin betrachtet wir.",
    ],
    [
      "Hier werden die Zutaten von Luna und Robins Apfelkuchen eingekauft. Nur die eingekauften Käsepicker mit Fähnchen passen nicht zum Rezept (Preis 1,99 Euro).",
      "Der E-Mail der Supermarkt-Filialleiter entnehmen wir, dass jemand beim Einkauf seinen Geldbeutel verloren hat.",
      "Die Dankkarte mit dem 2-Euro-Stück steht damit in Zusammenhang.",
      "Robin oder Luna haben also für jemand anderen spontan die 1,99 Euro für die Käsepicker ausgelegt.",
    ],
    [
      "Henrik und Katrin wollen auf gar keinen Fall in die USA umziehen. - Das ist schlecht für Ophelias Karriere und auch für Kais Karriere, da sein beruflicher Aufstieg an Ophelias hängt.",
      "Beide vereinbaren vertraulichkeit. Diese Info ist also für andere unbekannt.",
      "Henrik hat einen starken Bezug zu Dänemark. - Der Täter könnte das genutzt haben, um mit den Fähnchen auf den Wraps zu manipulieren, zu welchem der Wraps Henrik greift.",
      "Henrik möchte nicht Patenonkel für Luna und Robin sein, und auch deren Social-Media-Abitionen möchte er nicht unterstützen. - Ein Motiv?",
      "Wichtige Information: Henrik hat am 04.11.2021 seine Ketogene Diät aufgegeben. - Damit kommen für ihn mehr Speisen in Betracht. Zum Beispiel Wraps aus Weizen. Wer wusste davon?",
      "Henrik fragt nach der Namensgebung der Eichhörnchen: dazu ist ein Blick auf die Liste der Eichhörnchen von Kai und Katrin hilfreich. Stehen die Namen in Zusammenhang mit dem Geheimnisverrat bei Hulei-chem?",
    ],
    [
      "Entscheidend sind hier die Namen der Eichhörnchen von Kai und Katrin. Das sind die Vornamen deutscher Kanzler, nämlich Ludwig Erhardt, Kurt Georg Kiesinger und Willy Brandt.",
    ],
    [
      "Um die Fotos richtig zu interpretieren müsst Ihr das Ergebnis der Durchsuchung des Smartphones von Katrin kennen (Über den Online-Antrag).",
      "Jana hat einen Wrap vergiftet, und die US-Flagge darauf gesteckt (18:54 Uhr).",
      "Auf dem Foto von 21:34 Uhr seht Ihr, dass unter den 3 verzehrten Wraps auch der vergiftete Wrap ist.",
      "Entscheidend ist das Foto von Luna und Robins Influnetz-Seite. Dort sehen wir, dass jemand die US-Flagge auf dem vergifteten Wrap durch die Dänemark-Flagge ersetzt hat.",
    ],
    [
      "Hier ist die Liste mit Unverträglichkeiten und Diäten wichtig: Wer hätte überhaupt mit einem vergifteten Wrap (mit Weizen und Schafskäse/Feta) umgebracht werden können?",
      "Falls Henrik Olsen das Opfer sein sollte, wer wusste davon, dass er seine Diät einen Tag vorher abgebrochen hatte?",
    ],
    [
      "DeutschRapJaJae94 beinhaltet die Initialen von Jana J(ae/ä)ckel. Außerdem könnt ihr im Single-Portal sehen, dass eines von Janas Interessen/Hobbys DeutschRap ist.",
      "Für das Single-Portal ergeben sich damit die Infos, dass sie die Musikrichtung Deutschrap mag, dass sie gerne Computerspiele spielt, und dass sie 1994 geboren ist.",
    ],
    [
      "Auf dem Profil von Annika und Albert sehen wir, dass eines der Hobbys von Jana Klettern ist.",
      "Wichtig auf Luna und Robins Profil ist, dass in der Zeit zwischen 18:54 und kurz vor dem Essen die Fähnchen auf den Wraps vertauscht wurden. Außerdem scheinen die Beiden einen außergewöhnlichen Ehgreiz bezüglich ihres Social-Media-Profils zu haben, da @Baby&Eichis schon gegründet wurde, noch bevor Luna überhaupt schwanger war.",
    ],
    [
      "Luna und Robin können die ehrgeizigen Vorgaben aus dem Vertrag nur erfüllen, wenn sie mehr Eichhörnchen zugeteilt bekommen. Dann sind hohe Einnahmen möglich.",
    ],
    [
      "Kai arbeitet eng mit Ophelia Olsen zusammen. Sein beruflicher Aufstieg ist eng verknüpft mit Ophelias Aufstieg.",
    ],
    [
      "Das 2-Euro-Stück und der Text passen zu dem Vorfall mit dem verlorenen Geldbeutel an der Supermarkt-Kasse und den Käsepicker-Fähnchen auf dem Kassenzettel mit den Zutaten zum veganen Apfelkuchen.",
    ],
    [
      "Henrik Olsen hat bekannt gegeben, dass er eine ketogene/Keto-Diät macht. Das heißt er darf zum Beispiel keine Früchte essen und keine Weizenprodukte wie Wraps.",
      "Gäste, die nicht wissen, dass er diese Diät spontan abgebrochen hat, können daher als Täter oder Täterin ausgeschlossen werden",
    ],
    [
      "Albert hat einen Kostenvoranschlag für eine eigene Außenvoliere in Auftrag gegeben.",
      "Wenn Henrik als Gastgeber mit Außenvoliere ausfällt, könnten Albert und Annika seinen Platz einnehmen, und ihr Social-Media-Profil kräftig ausbauen.",
    ],
    [
      "Der Nachbar Lars Lippe leidet sehr unter der Entscheidung Henriks, sich einen Hund anzuschaffen. Ob das allerdings als Motiv ausreicht?",
    ],
    [
      "Die E-Mails passen zu dem Kassenbeleg und der Dankkarte mit dem 2-Euro-Stück. Derjenige der den Geldbeutel verloren hat, wollte noch am Tag der Vergiftung Käsepicker mit Fähnchen besorgen.",
    ],
    [
      "In den Bärlauch-Pesto-Wraps ist Weizen enthalten. Henrik Olsen hätte die mit seiner Keto-Diät also eigentlich gar nicht essen dürfen.",
      "Wer wusste, dass er seine Diät aufgegeben hatte?",
    ],
    [
      "Tilman hat einen starken Fokus auf Tierschutz.",
      "Die Influencer-Pärchen konkurrieren mit allen Mitteln um Eichhörnchen",
      "Wenn es um die Verteilung der Eichhörnchen geht, haben Henrik und Barbora das sagen",
      "Der Abend beginnt um 19:00 Uhr, aber erst um 20:30 Uhr wird das Buffet eröffnet. - Genügend Zeit für den Täter oder die Täterin, sich am Essen schaffen zu machen.",
    ],
    [
      "Es wird dazu aufgerufen, Familienangehörige zu fragen, ob etwas ungewöhnliches Beobachtet wurde. Ophelia könnte also mit Henrik über die Sache gesprochen haben. Auch Kai hat diese E-Mail sicherlich bekommen.",
      "Was hat es mit dem Namen Helmut I auf sich, und warum fragt Henrik Katrin wer die Namen (die Vornamen von ehemligen deutschen Bundeskanzlern sind) für ihre Eichhörnchen ausgesucht hat?",
    ],
    [
      "Um diesen Hinweis zu nutzen, müsst Ihr den Antrag des Online-Portals der Polizei Tillstetten korrekt bearbeitet haben.",
      "So findet Ihr heraus, dass zu Zeiten, zu denen Katrin geschlafen hat, auf ihr Smartphone zugegriffen wurde.",
      "Das kann nur Kai gewesen sein.",
    ],
    [
      "Lest genau, um zwei von Janas Hobbys nicht zu übersehen, die Beatrice erwähnt: Tennis und Architektur.",
      "In der ersten Produktionscharge des Spiels wird in Beatrices letzter Antwort (unten) der Name Alexander erwähnt. Das ist ein Versehen. Anstatt Alexander sollte da Henrik stehen. Wir bitten dies zu entschuldigen.",
    ],
    [
      "Gleicht die Zutaten des Apfelkuchens mit dem Kassenzettel des Supermarktes, der E-Mail der Filialleiter und der Dankkarte mit dem 2-Euro-Stück ab.",
    ],
    [
      "Ophelia hat die Karriere von Kai anscheinend maßgeblich gefördert. Wenn Ophelia aufstieg, konnte auch Kai profitieren.",
      "Vom Verrat der Firmengeheimnisse an die Tierschutzorganisation profitiert Ophelia direkt.",
      "Der nächste Karriereschritt in die USA würde allerdings von Henrik blockiert, da er in Europa bleiben möchte. - Ein (Teil-)Motiv?",
    ],
  ];

  const [auf, setAuf] = useState(false);

  return (
    <Fragment>
      {auf ? (
        <Grid item xs={1} sm={6} md={6} onClick={() => setAuf(!auf)}>
          <Item style={{ background: "#FFE75E", cursor: "pointer" }}>
            {" "}
            <p>{ubers[props.indi]}</p>
            <div className="txtdunkelgr txtkleiner">
              {hint[props.indi].map((item, index) => (
                <p>{item}</p>
              ))}
            </div>{" "}
          </Item>
        </Grid>
      ) : (
        <Grid item xs={1} sm={6} md={6} onClick={() => setAuf(!auf)}>
          <Item style={{ background: "#FFE75E", cursor: "pointer" }}>
            {" "}
            <p>{ubers[props.indi]}</p>
            <img className="thumb" src={thumbr[props.indi]} alt="thumbnail" />
          </Item>
        </Grid>
      )}
    </Fragment>
  );
}
