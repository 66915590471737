import React from "react";
import PropTypes from "prop-types";

//import AppBar from '@mui/material/AppBar';
import AppBar from "@mui/material/AppBar";

import Start from "./Start";
import Friendly from "./Friendly";
import Contact from "./Contact";
import Loesung from "./Loesung";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function TabComp(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar
        position="static"
        color="default"
        style={{ background: "#9ad3bc" }}
      >
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            centered
            // variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Hinweise" {...a11yProps(0)} style={{ color: "#555" }} />
            <Tab label="Lösung" {...a11yProps(1)} style={{ color: "#555" }} />
            <Tab label="Kontakt" {...a11yProps(2)} style={{ color: "#555" }} />
            {/* <Tab label="Start" {...a11yProps(3)} style={{ color: "#555" }} /> */}
          </Tabs>
        </div>
      </AppBar>
      {/* <TabPanel value={value} index={0}>
        <Start />
      </TabPanel> */}
      <TabPanel value={value} index={0} style={{ background: "#f3eac2" }}>
        <Friendly />
      </TabPanel>
      <TabPanel value={value} index={1} style={{ background: "#f3eac2" }}>
        <Loesung />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Contact />
      </TabPanel>
    </div>
  );
}
