import React, { Fragment, useState } from 'react'
import firebase from '../firebase'
import PayPal from '../components/PayPal'

const Registrieren = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [wrongEP, setWrongEP] = useState(false)
  const [emailFree, setEmailFree] = useState(false)
  const [open, setOpen] = useState(false)
  const [reg, setReg] = useState(false)

  //   const register = () => {
  //     firebase
  //       .auth()
  //       .createUserWithEmailAndPassword(email, password)
  //       .then(() => {
  //         resetInput()
  //       })
  //       .catch((err) => {
  //         console.error(err)
  //       })
  //   }

  const resetInput = () => {
    setEmail('')
    setPassword('')
  }

  const somth = () => {
    console.log(
      'Ist das erfüllt?',
      email !== '',
      confirmPassword !== '',
      password === confirmPassword,
      emailFree,
    )
    // firebase
    //   .auth()
    //   .fetchSignInMethodsForEmail(email)
    //   .then((methods) => {
    //     console.log(methods)
    //   })
  }

  function handleemail(e) {
    firebase
      .auth()
      .fetchSignInMethodsForEmail(e)
      .then((methods) => {
        console.log(methods.length)
        methods.length === 0 ? setEmailFree(true) : setEmailFree(false)
      })
      .catch((err) => {
        setEmailFree(false)
        console.error('Fehlerlog', 'emailFree', emailFree)
        console.error(err)
      })
    setEmail(e)
  }

  return (
    <Fragment>
      <div className="login-box">
        {!open ? (
          <div className="ten-margin">
            {!reg ? (
              <button onClick={() => setOpen(!open)}>zum Kauf</button>
            ) : null}
          </div>
        ) : (
          <div>
            <div>
              <h3 onClick={() => setOpen(!open)}>
                Gib Zugangsdaten ein, mit denen du in Zukunft auf das Spiel
                zugreifen kannst
              </h3>
            </div>

            {wrongEP ? (
              <p>Login fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben</p>
            ) : null}
          </div>
        )}

        <div>
          <h3 className="centerix centerios">Registrieren</h3>
          <div className="centerix centerios abstando">
            <input
              className="inputb"
              type="email"
              value={email}
              onChange={(e) => handleemail(e.target.value)}
              placeholder="email"
            />{' '}
            <br />
            <input
              className="inputb"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
            />
            <br />
            <input
              className="inputb"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Passwort wiederholen"
            />
            <br />
            {confirmPassword !== '' &&
            password === confirmPassword &&
            emailFree &&
            email.includes('.') &&
            email.includes('@') ? (
              <PayPal email={email} password={password} />
            ) : null}
            {/* <button className="ten-margin" onClick={register}>
              Registrieren
            </button> */}
            {/* <button className="butto" onClick={somth}>
              Registrieren
            </button> */}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Registrieren
