import React, { useState, Fragment } from "react";

import { experimentalStyled as styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function EAntrag(props) {
  const [auf, setAuf] = useState(false);

  const ubers = ["Tipp Lösung 1", "Name gesuchter Ort", "Direkt zur Lösung"];

  const hint = [
    [
      "Gebt den Code für den Intranet-Zugang ein (Nicht verwechseln mit dem Code für das Single-Portal).",
      "Gebt im Portal den Namen des Ortes an, an dem Katrin am betreffenden Wochenende war.",
      "Den Namen des Ortes wird Euch Jana verraten, wenn Ihr Euch mit Jana im Chat des Single-Portals unterhaltet, und die Unterhaltung unauffällig auf das Thema Kurzreisen und Wochenendtrips lenkt.",
    ],
    [
      "Der Name des Ortes an dem Katrin ein Wochenende verbracht hat, ist die schöne Stadt Aachen. Gebt also diesen Stadtnamen ein (achtet darauf keine Leerzeichen einzufügen).",
      "Beauftragt dann die Kollegen des Landeskriminalamtes mit der Durchsuchung durch Anklicken des Buttons.",
      "Es wird einige Sekunden dauern, da die Kolleginnen und Kollegen sich erst an die Arbeit machen können.",
      "Das hacken des Smartphones wird etwa 1 Minute dauern. Wartet während dieser Zeit einfach (ohne Buttons anzuklicken). Nach dieser Zeit erscheint automatisch ein Button der Euch zu den Ergebnissen bringt.",
    ],
    [
      "Wenn Ihr die Eingaben und die Beauftragung der Kolleginnen und Kollegen des LKA überspringen wollt, bekommt ihr hier direkt das Ergebnis der Durchsuchung:",
      <a
        href="https://pol-tillstetten.web.app/loesung"
        target="pol-tillstetten"
        rel="noopener noreferrer"
      >
        pol-tillstetten.web.app/loesung
      </a>,
      "Falls Ihr technische Probleme habt, versucht Euren Code mit einem vorangestellten x, also x123456, nochmal auf einem einzigen Gerät (nicht parallel auf mehreren) aus. Wir freuen uns auch über eine kurze Beschreibung des Problems, damit wir Probleme fixen können. E-Mail an info[at]manachlux.com . Danke schon mal dafür.",
    ],
  ];

  return (
    <Fragment>
      {auf ? (
        <Grid item xs={1} sm={6} md={6}>
          <Item style={{ background: "#FFE75E", cursor: "pointer" }}>
            {" "}
            <p onClick={() => setAuf(!auf)}>{ubers[props.indi]}</p>
            <div className="txtdunkelgr txtkleiner">
              {hint[props.indi].map((item, index) => (
                <p>{item}</p>
              ))}
            </div>{" "}
          </Item>
        </Grid>
      ) : (
        <Grid item xs={1} sm={6} md={6} onClick={() => setAuf(!auf)}>
          <Item style={{ background: "#FFE75E", cursor: "pointer" }}>
            {" "}
            <p>{ubers[props.indi]}</p>
          </Item>
        </Grid>
      )}
    </Fragment>
  );
}
