import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReContext from "./provider/ReContext";
import "./App.css";
import Main from "./pages/main";
import Rezept from "./pages/rezept";
import Indizien from "./pages/indizien";
import Ansatz from "./pages/ansatz";
import Antrag from "./pages/antrag";
import RLoesung from "./pages/rloesung";
import Motive from "./pages/motive";

import Single from "./pages/single";
import Impressum from "./pages/impressum";
import Datenschutz from "./pages/datenschutz";
import Online from "./pages/online";
import Till from "./pages/tillcomp.js";
import Krimispiel from "./pages/krimispiel";
import Ermittlung from "./pages/ermittlung";
import RezOnline from "./pages/rezeptonline.js";
import { AuthProvider } from "./provider/AuthProv";

function App() {
  return (
    <>
      <div>
        <AuthProvider>
          <ReContext>
            <Router>
              <Routes>
                <Route exact path="/" element={<Main />} />
                <Route exact path="/impressum" element={<Impressum />} />
                <Route exact path="/datenschutz" element={<Datenschutz />} />
                <Route exact path="/app" element={<Online />} />
                <Route exact path="/app/tillstetten" element={<Till />} />
                <Route exact path="/app/rezeptonline" element={<RezOnline />} />
                <Route exact path="/rezept" element={<Rezept />} />
                <Route exact path="/krimispiel" element={<Krimispiel />} />
                <Route exact path="/ermittlung" element={<Ermittlung />} />
                <Route exact path="/rezept/indizien" element={<Indizien />} />
                <Route exact path="/rezept/antrag" element={<Antrag />} />
                <Route exact path="/rezept/ansatz" element={<Ansatz />} />
                <Route exact path="/rezept/motive" element={<Motive />} />

                <Route exact path="/rezept/rloesung" element={<RLoesung />} />
                <Route exact path="/rezept/single" element={<Single />} />
              </Routes>
            </Router>
          </ReContext>
        </AuthProvider>
      </div>
    </>
  );
}

export default App;
