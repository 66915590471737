import React, { useState } from "react";
import Loesungeingabe from "./Loesungeingabe";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function Loesung() {
  const [auf, setAuf] = useState(false);
  const solution = [
    "Wer ist der Täter (Spoiler)?",
    "Simon Käufer hat seinen Vater mit einem Golfschläger erschlagen. Sein Motiv ist vielschichtig: Würde sein Vater sich von seiner Mutter scheiden lassen, stünde sie vor dem finanziellen Ruin. Aber auch er selbst muss finanzielle Konsequenzen fürchten. Den Geschäftsführerposten in der Firma seines Vaters möchte Alexander Käufer ihm wieder abnehmen. Zudem hat Alexander Käufer wissentlich gefälschte Ersatzteile in den Unfallwagen von Karl Liebig eingebaut. Hans Hoppe sitzt für diesen Unfall unschuldig im Gefängnis. Sollte das Verfahren wieder aufgerollt werden, könnte die Firma von Alexander Käufer zu Schadenersatz verurteilt werden. Zudem der Imageschaden für die Werkstattkette. Da die Firma die finanzielle Lebensgrundlage von Simon Käufer ist, hat er ein Interesse am Tod seines Vaters. Dann würde das Verfahren automatisch eingestellt. Zudem ist Simon Käufer als Sohn, zusammen mit seiner Mutter, Erbe der Firma.",
    "Simon Käufer hat nicht nur kein Alibi für die Tatzeit. Aus einer Email an seinen Vater geht hervor, dass Simon Käufer für ihn im Internet die Nussriegel SweetUnder bestellt hat. Der Mörder muss auch das Golfschlägerset Online bestellt haben. Und es gibt nur einen Besteller, der auch den Nussriegel SweetUnder zusammen mit dem Golfschläger bestellt hat. Das kann kein Zufall sein! Zusammen mit dem zeitlichen Ablauf der Bestellung im Internet und den Emails, werden diese Indizien zur Verurteilung von Simon Käufer ausreichen. Auch falls er nicht geständig sein sollte.",
  ];

  return (
    <div className="centering wcentering">
      <h2>Lösung</h2>
      <p className="anlei">
        Ihr habt den Täter ermittelt? Gebt hier den Vornamen des Täters oder der
        Täterin ein, um herauszufinden, ob ihr richtig liegt.
      </p>
      <Loesungeingabe />
      <p className="anlei">Um direkt die Lösung zu sehen, klickt hier:</p>
      <p className="reihe">
        <div>
          {!auf ? (
            <ArrowForwardIosIcon fontSize="inherit" color="disabled" />
          ) : (
            <div className="viz">
              <ArrowForwardIosIcon fontSize="inherit" color="disabled" />
            </div>
          )}
        </div>
        <span onClick={() => setAuf(!auf)}>{solution[0]}</span>
      </p>
      {auf ? (
        <div className="hinwifarbe">
          <p>{solution[1]}</p>
          <p>{solution[2]}</p>
          <h3>Gute Arbeit! Ihr habt den Fall gelöst!</h3>
        </div>
      ) : null}
    </div>
  );
}
