import React, { useState, Fragment } from "react";

import { experimentalStyled as styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function EMotiv(props) {
  const ubers = [
    "Jana",
    "Beatrice",
    "Henrik",
    "Lars",
    "Annika & Albert",
    "Barbora",
    "Ophelia",
    "Hund von Henrik",
    "Katrin",
    "Kai",
    "Tilman",
    "Luna & Robin",
  ];

  const hint = [
    [
      <p>
        <span className="abold">Katrin</span>
        <br /> Jana erpresst sie mit ihrem Verhältnis zu Lars
      </p>,
      <p>
        <span className="abold">Barbora</span>
        <br /> Eifersucht auf Janas Freundschaft mit Henrik
      </p>,
    ],
    [
      <p>
        Beatrice scheint sich aus den Konflikten in der Gruppe herauszuhalten.
        Ein Motiv ist nicht zu erkennen.
      </p>,
    ],
    [
      <p>
        <span className="abold">Jana</span>
        <br /> Trotz Hundeallergie hat Henrik einen Hund angeschafft. Vielleicht
        hat sich der Fokus von Henrik auch von der spendenfinanzierten Rettung
        von Gebäuden, Janas Hobby, auf die Rettung von Eichhörnchen gerichtet?
      </p>,
      <p>
        <span className="abold">Lars</span>
        <br /> Anschaffung des Hundes, der ihn um den Schlaf bringt
      </p>,
      <p>
        <span className="abold">Albert + Annika</span>
        <br /> Würde Henrik ausfallen, würde ihr Haus mit Außenvoliere Zentrum
        der Eichhörnchenrettung. Mit zusätlichen Followern auf Influnetz wäre zu
        rechnen.
      </p>,
      <p>
        <span className="abold">Ophelia</span>
        <br /> Henrik behindert durch seine Weigerung in die USA zu ziehen den
        nächsten Schritt ihrer hart erarbeiteten Karriere
      </p>,
      <p>
        <span className="abold">Kai</span>
        <br /> Henrik ist ihm auf der Spur, dass er derjenige ist, der
        Informationen über Hulei-chem an die Tierschutzorganisation gegeben hat.
        Zudem wäre mit Henriks Tod der Weg in die USA und weitere
        Karriereschritte für Ophelia frei, wovon Kai direkt profitieren könnte.
      </p>,
      <p>
        <span className="abold">Robin + Luna</span>
        <br /> Die beiden haben extra für ihren Influencer-Erfolg ein Baby
        bekommen. Jetzt brauchen sie für mehr Klicks mehr Eichhörnchen. Henrik
        verschließt sich dieser Strategie, da er nicht Patenonkel des Babys
        werden möchte und sich auch sonst nicht einspannen lässt.
      </p>,
    ],
    [
      <p>
        <span className="abold">Kai</span>
        <br /> Katrin hat eine Affäre mit Lars. Kai weiß davon, weil er heimlich
        nachts Katrins Smartphone durchsucht.
      </p>,
      <p>
        <span className="abold">Henrik</span>
        <br /> Lars hat ihn wegen Ruhestörung angezeigt und möchte mutmaßlich,
        dass er seinen Hund wieder hergibt.
      </p>,
      <p>
        <span className="abold">Barbora</span>
        <br /> Sie wollte unbedingt, dass Henrik einen Hund adoptiert. Lars
        gefährdet dieses Vorhaben.
      </p>,
    ],
    [
      <p>
        <span className="abold">Luna + Robin</span>
        <br /> Konkurrenten um Eichhörnchen, Follower und Geld. Albert + Annika
        gefährden ihre Lebensgrundlage, da sie versuchen ihr großes Haus als
        Argument anzuführen um mehr Eichhörnchen, und damit mehr
        Social-Media-Erfolg zu bekommen.
      </p>,
      <p>
        <span className="abold">Henrik</span>
        <br /> Würde Albert seine Außenvoliere errichten, würde Henrik seine
        Sonderstellung im Verein verlieren.
      </p>,
      <p>
        <span className="abold">Tilman</span>
        <br /> Er findet die beiden nutzen Tiere aus.
      </p>,
    ],
    [
      <p>
        <span className="abold">Jana</span>
        <br />
        Barbora ist der Grund, dass Henrik einen Hund hat, auf den sie
        Allergisch ist.
      </p>,
      <p>
        <span className="abold">Annika + Albert</span>
        <br /> Barbora steuert mit Henrik die Verteilung der Eichhörnchen, und
        Albert + Annika hätten gerne mehr Eichhörnchen.
      </p>,
      <p>
        <span className="abold">Luna + Robin</span>
        <br /> Barbora steuert mit Henrik die Verteilung der Eichhörnchen, und
        Albert + Annika hätten gerne mehr Eichhörnchen.
      </p>,
    ],

    [
      <p>
        <span className="abold">Henrik</span>
        <br />
        Ophelia möchte für ihre Karriere in die USA. Falls es darüber zur
        Trennung käme, wäre das finanziell sicher schlecht für Henrik, da
        Ophelia viel verdient.
      </p>,
      <p>
        <span className="abold">Barbora</span>
        <br />
        Laut Beatrices Aussage empfindet Barbora so etwas wie Eifersucht
        bezüglich Henrik. Erhofft sie sich vielleicht mehr? Ist Ophelia da im
        Weg? Sollte Ophelia in die USA gehen, fällt Henrik zudem as Gastgeber
        mit seiner Außenvoliere für den Verein aus.
      </p>,
      <p>
        <span className="abold">Katrin</span>
        <br />
        Sollte Ophelia doch in die USA gehen, könnte es sein, dass Kai auch dort
        hin befördert wird. Katrin möchte nicht umziehen.
      </p>,
    ],
    [
      <p>
        <span className="abold">Jana</span>
        <br />
        Ist allergisch auf den Hund, und kann deshalb nicht mehr zu den
        Vereinstreffen in Henriks Haus gehen, ohne allergische Reaktionen zu
        haben.
      </p>,
      <p>
        <span className="abold">Lars</span>
        <br />
        Kann wegen des Hundegebells nachts nicht mehr schlafen.
      </p>,
    ],
    [
      <p>
        <span className="abold">Kai</span>
        <br />
        Katrin hat eine Affäre mit Lars. Außerdem ist sie nicht bereit zusammen
        mit der gemeinsamen Tochter für seine Karriere in die USA zu ziehen.
      </p>,
      <p>
        <span className="abold">Jana</span>
        <br />
        Katrin ist Mitwisserin bei ihrem Plan, den Hund von Henrik zu vergiften.
      </p>,
    ],
    [
      <p>
        <span className="abold">Katrin</span>
        <br />
        Sie hat eine Affäre mit Lars.
      </p>,
      <p>
        <span className="abold">Lars</span>
        <br />
        Er hat eine Affäre mit Kais Frau.
      </p>,
      <p>
        <span className="abold">Ophelia</span>
        <br />
        Kai hat Firmengeheimnisse an den Tierschutzverein verraten, und Henrik
        ist ihm auf der Spur. Sollte herauskommen, dass ihr enger Mitarbeiter
        der Geheimnisverräter ist, wäre das schlecht für Ophelias Karriere.
      </p>,
    ],
    [
      <p>
        <span className="abold">Annika + Albert und Luna + Robin</span>
        <br />
        Tilman möchte verhindern, dass Eichhörnchen für Social-Media genutzt
        werdden können.
      </p>,
      <p>
        <span className="abold">Kai</span>
        <br />
        Tilman weiß, dass er Firmengeheimnisse an die Tierschutzorganisation
        verraten hat.
      </p>,
      <p>
        <span className="abold">Ophelia</span>
        <br />
        Tilmans Tierschutzorganisation hat ihrem Arbeitgeber schwer geschadet.
      </p>,
    ],
    [
      <p>
        <span className="abold">Annika + Albert</span>
        <br />
        Konkurrenten um Eichhörnchen, Follower und Geld. Luna + Robin betreiben
        den Aufbau ihres Social-Media-Auftritts aggressiver und haben auch einen
        Werbedeal mit Hulei-chem bekommen.
      </p>,
      <p>
        <span className="abold">Tilman</span>
        <br />
        Er findet die beiden nutzen Tiere aus.
      </p>,
    ],
  ];

  const [auf, setAuf] = useState(false);

  return (
    <Fragment>
      {auf ? (
        <Grid item xs={1} sm={6} md={6} onClick={() => setAuf(!auf)}>
          <Item style={{ background: "#FFE75E", cursor: "pointer" }}>
            {" "}
            <p>{ubers[props.indi]}</p>
            <div className="txtdunkelgr texteins">
              {hint[props.indi].map((item, index) => (
                <p>{item}</p>
              ))}
            </div>{" "}
          </Item>
        </Grid>
      ) : (
        <Grid item xs={1} sm={6} md={6} onClick={() => setAuf(!auf)}>
          <Item style={{ background: "#FFE75E", cursor: "pointer" }}>
            {" "}
            <p>{ubers[props.indi]}</p>
          </Item>
        </Grid>
      )}
    </Fragment>
  );
}
