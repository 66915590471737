import React, { useState, useContext } from "react";

import { useNavigate } from "react-router-dom";
import firebase from "../firebase";
import AppContext from "../provider/AppContext";

export default function Code() {
  const { setCdr, erm, setErm, cdr } = useContext(AppContext);
  const ref = firebase.firestore();

  const [email, setEmail] = useState("");
  const [valida, setValida] = useState("");

  const navigate = useNavigate();
  function handleClick() {
    navigate("/ermittlung");
  }

  const date = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);
  let timestamp;

  const [loading, setLoading] = useState(true);

  let codeDate;
  function register(some) {
    console.log(email);
    if (email !== "") {
      ref
        .collection(email)
        .doc("purchase")
        .get()
        .then(function (doc) {
          if (doc.exists) {
            console.log("fillee exists", doc.data().crtd);
            codeDate = doc.data().crtd;
            console.log(codeDate.toDate() > date);
            let hours = Math.abs(codeDate.toDate() - date) / 36e5;
            console.log("codeDate", codeDate);
            console.log("abstand in Stunden", hours);
            setValida("");
            setLoading(false);
            if (
              codeDate.toDate().getTime() ===
              new Date("January 1, 2000 00:00:00").getTime()
            ) {
              var myTimestamp = firebase.firestore.Timestamp.fromDate(
                new Date(Date.now())
              );
              ref
                .collection(email)
                .doc("purchase")
                .update({ crtd: myTimestamp })
                .catch((err) => {
                  console.error(err);
                })
                .then(
                  ref
                    .collection("coll")
                    .doc(email)
                    .update({
                      crtd: myTimestamp,
                    })
                    .catch((err) => {
                      console.error(err);
                    })
                    .then(() => {
                      setErm(true);
                      setCdr(some);
                      console.log("Code neuaktiv!");
                    })
                );
            } else if (codeDate.toDate() > date) {
              setCdr(some);
              setErm(true);
              console.log("Code gültig!", cdr);
            } else {
              setValida(
                "Der Code ist abgelaufen. In der App erzeugte Codes für Freunde sind nur 48 Stunden gültig."
              );
            }

            //     setCdr(some);
          } else {
            console.log("No such document userlog!");
            email !== "start"
              ? setValida(
                  "Ungültiger Code. Überprüfe die Schreibweise, und achte darauf z.B. alle Leerzeichen zu entfernen."
                )
              : console.log("initial run");
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log("Error getting document userlog:", error);
          setValida(
            "Upps, es gab ein Problem. Das Überprüfen des Codes hat nicht funktioniert nicht. Versuche es bitte später nochmal."
          );
          setLoading(false);
        });
    } else {
      setEmail("empty");
      console.log("email is empty!");
      setValida("");
      setLoading(false);
    }
  }

  return erm ? (
    <div className="centerix centerios abstando">
      <button className="butto" onClick={handleClick}>
        Zum Fall
      </button>
    </div>
  ) : (
    <div className="centerix centerios abstando">
      <input
        className="inputb"
        type="number"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Code"
      />{" "}
      <br />
      <p className="valida">{valida}</p>
      <button className="butto" onClick={() => register(email)}>
        Absenden
      </button>
    </div>
  );
}
