import React, { useContext } from "react";
import AppContext from "../provider/AppContext";
import { AuthContext } from "../provider/AuthProv";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Auftrag from "../components/Auftrag";
import Beweise from "../components/Beweise";
import Tatverd from "../components/Tatverd";
import Tipps from "../components/Tipps";
import Contact from "../components/Contact";
import LogoutB from "../logs/LogoutB";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Tillcomp() {
  const { currentUser, isLogged } = useContext(AuthContext);
  const { reg } = useContext(AppContext);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  function handleClick() {
    navigate("/app");
  }
  return !reg && !isLogged ? (
    <div>
      <div>
        <AppBar
          position="static"
          color="default"
          style={{ background: "#9ad3bc" }}
        >
          <Toolbar>
            <Typography variant="h6">Fall Tillstetten</Typography>
          </Toolbar>
        </AppBar>
      </div>

      <div className="centering wcentering">
        <p>
          Für die Online-Version des Falls "Tod in Tillstetten" benötigst du
          einen Zugang. Zum eingeben eines Freischaltungscodes oder zum Kaufen:
        </p>
        <div className="centerix centerios">
          <button className="butto" onClick={handleClick}>
            Zum Fall
          </button>
        </div>
      </div>
    </div>
  ) : isLogged ? (
    <div>
      <AppBar
        position="static"
        color="default"
        style={{ background: "#9ad3bc" }}
      >
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            centered
          >
            <Tab label="Auftrag" {...a11yProps(0)} style={{ color: "#555" }} />
            <Tab
              label="Tatverdächtige"
              {...a11yProps(1)}
              style={{ color: "#555" }}
            />
            <Tab label="Beweise" {...a11yProps(2)} style={{ color: "#555" }} />
            <Tab label="Tipps" {...a11yProps(3)} style={{ color: "#555" }} />
            <Tab label="Kontakt" {...a11yProps(4)} style={{ color: "#555" }} />
            {isLogged ? (
              <Tab icon={<LogoutB />} aria-label="logout" {...a11yProps(5)} />
            ) : null}
          </Tabs>
        </div>
      </AppBar>
      <TabPanel value={value} index={0} style={{ background: "#f3eac2" }}>
        <Auftrag />
      </TabPanel>
      <TabPanel value={value} index={1} style={{ background: "#f3eac2" }}>
        <Tatverd />
      </TabPanel>
      <TabPanel value={value} index={2} style={{ background: "#f3eac2" }}>
        <Beweise />
      </TabPanel>
      <TabPanel value={value} index={3} style={{ background: "#f3eac2" }}>
        <Tipps />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Contact />
      </TabPanel>
    </div>
  ) : (
    <div>
      <AppBar
        position="static"
        color="default"
        style={{ background: "#9ad3bc" }}
      >
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            centered
          >
            <Tab label="Auftrag" {...a11yProps(0)} style={{ color: "#555" }} />
            <Tab
              label="Tatverdächtige"
              {...a11yProps(1)}
              style={{ color: "#555" }}
            />
            <Tab label="Beweise" {...a11yProps(2)} style={{ color: "#555" }} />
            <Tab label="Tipps" {...a11yProps(3)} style={{ color: "#555" }} />
            <Tab label="Kontakt" {...a11yProps(4)} style={{ color: "#555" }} />
          </Tabs>
        </div>
      </AppBar>
      <TabPanel value={value} index={0} style={{ background: "#f3eac2" }}>
        <Auftrag />
      </TabPanel>
      <TabPanel value={value} index={1} style={{ background: "#f3eac2" }}>
        <Tatverd />
      </TabPanel>
      <TabPanel value={value} index={2} style={{ background: "#f3eac2" }}>
        <Beweise />
      </TabPanel>
      <TabPanel value={value} index={3} style={{ background: "#f3eac2" }}>
        <Tipps />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Contact />
      </TabPanel>
    </div>
  );
}
