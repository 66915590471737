import React, { useState, Fragment } from "react";
import tilltitel from "../tilltitel.png";
import rezepttitel from "../rezepttitel.png";
import { useNavigate } from "react-router-dom";
import TabComp from "./TabComp";

export default function Start() {
  const [auswahl, setAuswahl] = useState(true);
  const navigate = useNavigate();
  function clickT() {
    setAuswahl(false);
  }

  function clickR() {
    navigate("/rezept");
  }

  function handleClick() {
    navigate("/impressum");
  }

  function clickDS() {
    navigate("/datenschutz");
  }
  return (
    <Fragment>
      {auswahl ? (
        <div className="centering wcentering centerios">
          <h3>Willkommen!</h3> <p className="antword">Wählt Euer Spiel.</p>
          <div>
            <div className="pointer" onClick={clickT}>
              {" "}
              <img className="start-img" src={tilltitel} alt="Tillstetten" />
            </div>
            <div className="pointer" onClick={clickR}>
              {" "}
              <img
                className="start-img"
                src={rezepttitel}
                alt="Rezept zum Sterben"
              />
            </div>
          </div>
          <div className="abstanduklein"></div>
          <div className="centerios texteins">
            {" "}
            <p>
              <span onClick={handleClick} className="impress abstandr">
                Impressum
              </span>{" "}
              <span onClick={clickDS} className="impress">
                Datenschutz
              </span>
            </p>{" "}
          </div>
        </div>
      ) : (
        <div>
          <TabComp setAuswahl={setAuswahl} />
        </div>
      )}
    </Fragment>
  );
}
