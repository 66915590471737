import React from "react";
import { useNavigate } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import firebase from "./../firebase";
import PayPal from "../components/PayPal";

export default function LogoutB() {
  const navigate = useNavigate();
  const logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log("Logout erfollgtreich");
        navigate("/app");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div onClick={logOut}>
      {/* <PayPal /> */}
      <ExitToAppIcon />
    </div>
  );
}
