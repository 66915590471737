import React, { useState, Fragment, useContext } from "react";
import AppContext from "../provider/AppContext";
import PayPal from "../components/PayPal";

export default function MailZahl() {
  const { ant, setAnt } = useContext(AppContext);
  const [mai, setMai] = useState(false);

  const [valida, setValida] = useState("");

  function valf() {
    if (ant === "" || !(ant.includes("@") && ant.includes("."))) {
      setValida(
        "Bitte gib eine gültige Email-Adresse an. An diese wird nach dem Kauf Dein Aktivierungscode gesendet."
      );
    } else {
      setValida("");
      setMai(true);
    }
  }

  return (
    <Fragment>
      <div>
        {mai ? (
          <div>
            <p>
              <span>Deine E-Mail: {ant}</span>
              <button className="importbutton" onClick={() => setMai(false)}>
                Zurück
              </button>
            </p>
            <PayPal eno={ant} />
          </div>
        ) : (
          <div>
            <input
              className="inputb ten-margin antwort"
              type="text"
              value={ant}
              onChange={(e) => setAnt(e.target.value)}
              placeholder="Deine Email"
            />{" "}
            <button className="importbutton" onClick={valf}>
              Absenden
            </button>
            <p className="valii">{valida}</p>
          </div>
        )}
      </div>
    </Fragment>
  );
}
