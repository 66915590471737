import React, { useContext } from "react";
import AppContext from "../provider/AppContext";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const btext = [
  [
    "Abbildungen der Fingerabdrücke von Carolin Käufer, Simon Käufer, Michaela Maurer, Heike Hoppe, Lilly Liebig, Ludwig Liebig",
  ],
  [
    "Karte die zeigt, dass die Fahrtzeit zwischen Tillstetten und Grabenbruck mindestens 41 Minuten beträgt",
  ],
  [
    "Gefälschte Ersatzteile: Freispruch nach tödlichem Unfall",
    "Tillstetten, 07.02.2020",
    "Das Amtsgericht Tillstetten hat Alexander K. am Freitag vom Vorwurf der fahrlässigen Tötung aus Mangel an Beweisen freigesprochen.",
    "Die Staatsanwaltschaft hatte Anklage erhoben, weil ein 30-Jähriger im vergangenen Jahr mit seinem Auto tödlich verunglückt war, nachdem die Bremsen seines Autos versagt hatten. Die Bremsteile, die zum Unfall führten, waren nicht zugelassene, billige Imitate, die zuvor durch eine der Werkstätten des Angeklagten im Unglücksauto eingebaut worden waren.",
    "Der Angeklagte bestritt nicht, dass der Einbau von einer seiner Autowerkstätten vorgenommen worden sei, beschuldigte aber einen Autoteilehändler, von dem er alle Teile beziehe, die im Unfallauto verbauten Teile als Originalware gekauft zu haben.",
    "Der Argumentation der Staatsanwaltschaft, es sei unglaubwürdig, dass der Angeklagte Originalteile nicht von billigen Imitaten unterscheiden könne, wollte das Gericht nicht folgen, und sprach Alexander K. frei.",
  ],
  [
    "Autoteilehändler nach tödlichem Unfall verurteilt",
    "Tillstetten, 15.10.2020",
    "Der Autoteilehändler Hans H. ist gestern vom Amtsgericht Tillstetten wegen fahrlässiger Tötung zu 7 Jahren Haft verurteilt worden. Im vergangenen Jahr war ein 30-Jähriger mit seinem Auto tödlich verunglückt, als die Bremsen seines Autos versagt hatten. Die Bremsen waren zuvor von einer Werkstatt eingebaut worden, die Ersatzteile des Angeklagten bezog.",
    "Bei einer Durchsuchung des Betriebs des Angeklagten waren nach einem Hinweis eines früheren Geschäftspartners zahlreiche nicht zugelassene Imitate verschiedenster Autoersatzteile entdeckt worden.",
    "Der Angeklagte räumte ein, mit gefälschten Ersatzteilen gehandelt zu haben, beteuerte aber hinsichtlich der Anklage der fahrlässigen Tötung seine Unschuld, und bestand darauf, dass alle seine Abnehmer gewusst hätten, dass es sich um gefälschte Ware handelte. Seine Verteidigung, er habe die spezifischen Teile, die zum tödlichen Unfall führten, nie in seinem Sortiment geführt, und diese Teile nie vertrieben, folgte das Gericht nicht.",
    'Die Eltern und die Verlobte des Verunglückten, die beim Prozess anwesend waren, äußerten sich erleichtert. "Es ist wichtig zu wissen, dass der Verantwortliche zur Rechenschaft gezogen wird. Wir hätten es nicht ertragen, wenn er damit einfach durchgekommen wäre" sagte Jana Jäckel, die mit dem Getöteten verlobt gewesen war, nach dem Urteilsspruch.',
  ],
  [
    "Viel zu früh wurdest Du uns genommen",
    "Karl Liebig",
    "04.12.1988 - 02.02.2019",
    "Ruhe in Frieden.",
    "Am Ende wird die Gerechtigkeit siegen.",
    "In tiefer Trauer, Deine Verlobte Jana Jäckel und Deine Eltern, Lilly und Ludwig Liebig.",
  ],
  [
    "Kanzlei Dr. Bernhard Schröder",
    "Fachanwalt für Familienrecht",
    "Hohe Straße 3",
    "67775 Tillstetten",
    "Frau",
    "Carolin Käufer",
    "Lange Straße 2",
    "67775 Tillstetten",
    "19.01.2021",
    "Betrifft: Änderung Ihres Ehevertrags",
    "Sehr geehrte Frau Käufer,",
    "wie besprochen, melde ich mich nach Durchsicht der Unterlagen mit meiner Einschätzung bei Ihnen. Leider sehe ich keine Möglichkeit die Wirksamkeit Ihres Ehevertrags gerichtlich anzufechten. Im Falle einer Scheidung würden Sie also lediglich eine Einmalzahlung von 10.000 Euro erhalten.",
    "Vermögensgegenstände, die Sie aber aus der Ehe mitnehmen können, sind definitiv Geschenke. Dazu gehört auch das Pferd, das Ihnen Alexander Käufer für 120.000 Euro gekauft und geschenkt hat.",
    "Mit freundlichen Grüßen",
    "gez. Dr. Bernhard Schröder",
    "Fachanwalt für Familienrecht",
  ],
  [
    "20. Januar 2021",
    "[Theresa Mink]: Hey meine Liebe, wie geht's Dir? Hast Du schon was Neues von deinem Anwalt gehört wegen des Ehevertrags?",
    "[Carolin Käufer]: Ja. Ich kann nichts gegen den Ehevertrag tun. Ich muss wohl oder übel mein Pferd verkaufen, damit ich Geld für einen Neustart habe. Und das nach so vielen Jahren Ehe.",
    "Das macht mich so wütend! Alexander ist das Allerletzte!",
    "[Theresa Mink]: Wem sagst Du das ... Markus wurde von Alexander nach all den Jahren auch hintergangen. Er hatte ihm den Geschäftsführerposten der Werkstattkette fest versprochen. Wie bei Dir, entzieht sich Alexander allen seinen Verpflichtungen! Er denkt, er könne damit einfach durchkommen.",
    "Wir können das nicht so einfach hinnehmen! Bleib stark.",
  ],
  [
    "22. Januar 2021",
    "[Michaela Maurer]: Hallo Carolin, ich habe gehört, dass du Maxi schon wieder verkaufen willst.",
    "Warum denn?",
    "Du liebst es doch zu reiten, und Maxi ist dein ein und alles!?",
    "[Carolin Käufer]: Ja das stimmt. Ich würde Maxi niemals hergeben. Das kannst Du mir glauben, Michaela. Aber ich muss...",
    "Wenn Alexander sich von mir scheiden lässt, habe ich keine Wahl. ich brauche dann das Geld. Sonst würde ich nicht im Traum daran denken Maxi zu verkaufen.",
  ],
  [
    "03. Januar 2021",
    "[Ludwig Liebig]: Hallo Lilly, ich habe heute einen unerwarteten Anruf von der Frau des Ersatzteilehändlers bekommen, der wegen des Todes von Karl im Gefängnis sitzt (Hans Hoppe).",
    "Die beiden behaupten weiter, er sei unschuldig, und sind entsprechend wütend. Frau Hoppe hatte die Idee, im Gefängnis an ihrem Mann einen Lügendetektor-test durchführen zu lassen.",
    "Ich war erstmal überrascht von dem Vorschlag. Was meinst Du dazu?",
    "[Lilly Liebig]: Ich hole Jana gerade vom Flughafen ab, und sie sitzt bei mir im Auto.",
    "Wir beide finden, wir sollten Hans Hoppe einen Lügendetektor-Test machen lassen.",
    "Wenn er das schon anbietet...",
    "Was haben wir zu verlieren?",
    "[Ludwig Liebig]: OK. Ich werde jemanden suchen, der den Test durchführt.",
    "Falls in dem Gerichtsprozess der Falsche verurteilt wurde, werden wir es heraufinden.",
  ],
  [
    "Fachhochschule Tillstetten",
    "Bibliotheksausweis 0705237688",
    "Bianca Bauer",
  ],
  [
    "Tillstetten, 05.12.2019",
    "Michaela Maurer",
    "Reitlehrerin",
    "Feldstr. 27",
    "67765 Tillstetten",
    "An",
    "Carolin Käufer",
    "Lange Straße 2",
    "67775 Tillstetten",
    "Hallo Carolin,",
    "wie gewünscht, anbei das Original-Zertifikat zur Abstammung von Maxi das ich für Dich noch besorgt habe. Ich bin echt glücklich, dass ich den Kauf für Dich organisieren und abwickeln konnte, denn von einem so edlen Gestüt ist es sehr schwierig an ein solches Pferd zu kommen.",
    "Alexander hat den Kaufpreis von 120.000 Euro übrigens bereits auf mein Konto überwiesen. Es ist also alles erledigt.",
    "Ich freue mich schon, wenn wir mit Maxi gemeinsam ausreiten!",
    "Liebe Grüße",
    "gez. Michaela",
  ],
  [
    "Gestüt Steilfels",
    "Abstammungszertifikat",
    "Aus unserer Zucht:",
    "Mutter: Redouane -  Vater: Grand",
    "Maxi - Geboren am 11.04.2016, Geschlecht: männlich",
    "Datum 02.12.2019, gezeichnet Stefan Steilfels",
  ],
  [
    "Anton Anderson,",
    "Pferdegutachter",
    "Hinterstr. 43",
    "67743 Tillstetten",
    "An",
    "Carolin Käufer",
    "Lange Straße 2",
    "67775 Tillstetten",
    "18.01.2021",
    "Sehr geehrte Frau Käufer,",
    "danke für Ihre Anfrage. Gerne gebe ich Ihnen eine Schätzung über den Wert Ihres Pferdes ab. Ich kann Ihr Pferd Maxi mit den Angaben, die Sie mir geschrieben haben, nicht ganz zuordnen. Sie hatten erwähnt, dass Ihr Pferd aus der renommierten Zucht Gestüt Steilfels kommt. Recherchieren Sie den Stammbaum Ihres Pferdes am besten auf der Homepage des Gestüts (www.gestuet-steilfels.de) und schicken Sie mir dann die Daten.",
    "Freundliche Grüße,",
    "Anton Anderson,",
    "Pferdegutachter",
  ],
  [
    "Arbeitsunfähigkeitsbescheinigung - Ausfertigung zur Vorlage beim Arbeitgeber",
    "AOK Tillstetten",
    "Erik Lindt, Geboren 07.02.1987, Gartenweg 6, 67775 Tillstetten",
    "Erstbescheinigung",
    "arbeitsunfähig seit 02.02.2021",
    "voraussichtlich arbeitsunfähig bis 03.02.2021",
    "Festgestellt am 02.02.2021",
    "Praxis Dr. Alois Ambach - Facharzt für Allgemeinmedizin - Kolbenstraße 44A, 67775 Tillstetten",
  ],
  [
    "Datenerhebung Lügendetektor-Test Hans Hoppe, 22.01.2021 in der Justizvollzugsanstalt Grabenbruck",
    "Elektrodermale Aktivität (Änderung Hautwiderstand durch Elektroden gemessen).",
    "Ausschläge bei der Messung an folgenden Zeitmarken: 0:10, 0:30, 0:42, 1:01, 1:22, 2:03",
  ],
  [
    "Datenerhebung Lügendetektor-Test Hans Hoppe, 22.01.2021 in der Justizvollzugsanstalt Grabenbruck",
    "Fragen und Anweisungen des Testers",
    '[00:01] Beantworten Sie bitte die ersten 4 Fragen mit "Ja", um die Geräte zu testen',
    '[00:10] Ist der Vorname Ihrer Frau Gabriele? - Antwort: "Ja"',
    '[00:20] Ist der Vorname Ihrer Frau Heike? - Antwort: "Ja"',
    '[00:30] Ist Ihr Name Hans? - Antwort: "Ja"',
    '[00:42] Hat man Sie zu diesem Test gezwungen? "Ja"',
    '[00:51] Okay, ab jetzt beantworten Sie bitte die Fragen wahrheitsgemäß mit "Ja" oder "Nein"',
    '[01:01] Sitzen Sie gerade im Gefängnis? - Antwort: "Ja"',
    '[01:22] Haben Sie wissentlich gefälschte Autoteileverkauft? - Antwort: "Ja"',
    '[01:45] Wussten alle Ihre Kunden, also die Werkstätten, dass die Ersatzteile gefälscht waren? - Antwort: "Ja"',
    '[02:03] Haben Sie die Teile, die zum Unfall von Karl Liebig geführt haben, in Ihrem Sortiment geführt? - Antwort: "Nein"',
    '[02:13] Das heißt, Sie können zu 100% ausschließen, dass die Unfallteile von Ihnen stammen? - Antwort: "Ja"',
  ],
  [
    "Herzfrequenz",
    "Bei der Messung der Herzfrequenz gibt es deutliche Ausschläge bei ca. 00:10, 00:42, 01:45",
    "Lügendetektor: Wenn direkt im Anschluss an die Fragestellung die elektrodermale Aktivität ausschlägt, und die Herzfrequenz steigt = Lüge. Andernfalls = Wahrheit.",
  ],
  [
    "Stadt Grabenbruck",
    "Verwarnung",
    "Tattag und -zeit: Dienstag, 02.02.2021 08:43 Uhr",
    "Tatort: Grabenbruck, Schulstraße 17",
    "Tatvorwurf: Sie parken im Bereich eines absoluten Halteverbots",
    "Amtliches Kennzeichen: TLS AB 290",
    "Fahrzeugart: PKW",
    "Beweis: Foto",
    "Zeuge: Verkehrsüberwacher/in Nübel",
    "Wegen dieser Verkehrsordnungswidrigkeit werden Sie hiermit verwarnt, Sie können das Verfahren abschließend erledigen, indem Sie das Verwarnungsgeld vollständig innerhalb einer Woche unter Angabe des Aktenzeichens auf das Konto der Stadtverwaltung einzahlen.",
    "Aktenzeichen: 543 02 2368268 S",
  ],
  [
    "[Die beiden abgebildeten Fingerabdrücke sind separat als Vergrößerung in den folgenden Beweisen enthalten.]",
    "An die",
    "AlexKäuf Werkstätten GmbH",
    "z.Hd. Alexander Käufer Persönlich",
    "Gewerbestr. 14",
    "67775 Tillstetten",
    "Wir wissen, dass Ihre Behauptung, Ersatzteile für Ihre Werkstätten ausschließlich von Hans Hoppe gekauft zu haben, eine Lüge ist. Der Grund für Ihre Behauptung kann nur sein, dass Sie gewusst haben, dass Teile ohne Zulassung in Ihrer Werkstatt verbaut werden!",
    "Einen Unschuldigen zu beschuldigen wird Sie nicht retten.",
    "Das ist jetzt die letzte Chance für Sie. Stellen Sie sich der Polizei und legen Sie ein umfassendes Geständnis ab. Auch wenn ein Geständnis bedeutet, dass Sie dann Ihren Besitz verlieren, weil Sie sich mit Betrug bereichert haben. Wenn Sie das nicht machen, werden andere Konsequenzen gezogen!",
    "X",
  ],
  ["Vergößerung des 1. Fingerabdrucks auf dem anonymen Brief"],
  ["Vergößerung des 2. Fingerabdrucks auf dem anonymen Brief"],
  [
    "LieferBit Online Shop",
    '[Hinweis: Alle anonym mit der Kryptowährung Bitcoin bezahlten, und dann an Packstationen gelieferte Bestellungen des Golfschlägersets "Greenschoen"]\'),1.) 02.01.2021 - Golfset Greenschoen, Badeschlappen Schlappdance Gr. 43',
    "2.) 04.01.2021 - Golfset Greenschoen",
    "3.) 04.01.2021 - Golfset Greenschoen",
    "4.) 06.01.2021 - Golfset Greenschoen, Golfsocken schwarz Gr. 45 10er",
    "5.) 07.01.2021 - Golfset Greenschoen, SweetUnder Nussriegel 10 x 65 Gramm",
    "6.) 07.01.2021 - Golfset Greenschoen, Sonnencreme BrandProtect 150 ml",
    "7.) 12.01.2021 - Golfset Greenschoen, Kopfhörer Surrrund",
    "8.) 18.01.2021 - Golfset Greenschoen, Hundeleckerli Dogmmm 10er, Kindergeburtstag Konfetti 500 Gramm, Partyhüte 20 Stück",
    "9.) 23.01.2021 - Golfset Greenschoen",
  ],
  [
    "Bibliothek Fachhochschule Tillstetten",
    "Mo-Fr 08:00 - 20:00 / Sa 10:00 - 18:00",
    "Quittung Ausweisnummer 0705237688",
    "Ausweis gültig bis 19.11.2023",
    "Ausleihen:",
    "DT98402 SL 78 - 16.02.2021 - Grundlagen d. Rechnungswesens",
    "DT87676 SL 77 - 16.02.2021 - Besteuerung von Unternehmen",
    "Summe der Ausleihen: 2",
    "Insgesamt entliehen: 2",
    "Datum 02.02.2021 Uhrzeit: 08:29 Uhr",
  ],
  [
    "GUMBL Suchmaschine & Bewertungen",
    "AlexKäuf Werkstätten GmbH",
    "12.12.2020, 2/5 Sterne",
    "Wir lassen schon seit Jahren unsere Autos hier reparieren, und waren vom schnellen und kompetenten Service immer begeistert. Seit einigen Monaten hat sich das aber deutlich zum Schlechteren verändert. Jetzt muss man lange warten, und die Preise sind auch deutlich gestiegen.",
    "17.12.2020, 1/5 Sterne",
    "Bin schon lange Kunde und war immer zufrieden, aber seit Neuestem hat man nicht mehr das Gefühl, dass man als Kunde König ist. Bitte wieder einen Service so wie früher werden!",
    "11.01.2021, 1/5 Sterne",
    "Mitarbeiter scheinen gestresst und überfordert. Hatte feste Zusage, dass mein Auto nach 3 Tagen fertig ist, nach 10 Tagen werde ich immer noch vertröstet. Was ist da los? Organisation scheint drunter und drüber zu gehen.",
    "14.01.2021, 2/5 Sterne",
    "Seit dem Wechsel der Geschäftsführung nicht mehr der gewohnte Service wie früher. Schade!",
  ],
  [
    "Bestenliste - Hall of Fame",
    "1. NorthHero 13900 Punkte - erreicht am 13.01.2021 um 20:01 Uhr",
    "2. PowerRider 12219 Punkte - erreicht am 02.02.2021 um 08:34 Uhr",
    "3. Elygy 11221 Punkte - erreicht am 02.01.2021 um 15:56 Uhr",
    "4. Plibuzz 9677 Punkte - erreicht am 12.11.2020 um 21:29 Uhr",
    "5. CloinFerrl 9453 Punkte - erreicht am 29.12.2020 um 11:39 Uhr",
  ],
  [
    "[Hinweis: Fundbüro des Flughafens: Liste aller Fundsachen, die am 02.02.2021 von den Eigentümern dort abgeholt wurden]",
    "Datum des Fundes: 13.12.2020 - Fundort: Sitzbereich Terminal 1 - Beschreibung der Fundsache: Blau-weißer Regenschirm - Datum der Abholung: 02.02.2021 07:32 Uhr - Nachweis des Eigentümers bei Abholung: Genaue Beschreibung des Gegenstands & Datumsangabe",
    "Datum des Fundes: 03.01.2021 - Fundort: Flughafen-Kaffee Arrive&Go - Beschreibung der Fundsache: Rote Lesebrille - Datum der Abholung: 02.02.2021 08:30 Uhr - Nachweis des Eigentümers bei Abholung: Fotos auf denen Eigentümerin Brille trägt, Angabe Verlustdatum und Flugticket",
    "Datum des Fundes: 11.11.2020 - Fundort: Duty-Free-Shop - Beschreibung der Fundsache: Smartphone Samsung - Datum der Abholung: 02.02.2021 09:11 Uhr - Nachweis des Eigentümers bei Abholung: Erfolgreiche PIN-Eingabe",
    "Datum des Fundes: 01.02.2021 - Fundort: Sitzbereich Terminal 2 - Beschreibung der Fundsache: Stofftier Blau - Datum der Abholung: 02.02.2021 11:35 Uhr - Fotos mit Eigentümerin und Stofftier vorgezeigt.",
    "Datum des Fundes: 14.01.2021 - Fundort: Aussichtsplattform - Beschreibung der Fundsache: Fernglas, schwarz - Datum der Abholung: 02.02.2021 13:44 Uhr - Rechnung für Fernglas vorgezeigt",
    "Datum des Fundes: 02.02.2021 - Fundort: Raucherbereich - Beschreibung der Fundsache: Goldenes Zigarettenetui - Datum der Abholung: 02.02.2021 15:02 Uhr - Eingravierter Name mit Personalausweis abgeglichen",
  ],
  [
    "[Hinweis: Ergebnis Überprüfung: Hans Hoppe hatte am 02.02.2021 Besuch]",
    "Besucherrichtlinien Justizvollzugsanstalt Grabenbruck",
    "Besuchstage:",
    "Montag  -  Alle Besucher nach vorheriger Anmeldung",
    "13:00 – 15:30 Uhr",
    "Dienstag – Besuchertag für Ehepartner",
    "9:00 – 10:30 Uhr",
    "Samstag – Alle Besucher nach vorheriger Anmeldung",
    "13:00 – 17:30 Uhr",
    "Hinweis für Besucher und Besucherinnen:",
    "Seien Sie 10 Minuten vor Einlass für die Sicherheitsüberprüfung anwesend. Bitte haben Sie Verständnis, dass wir Besucher, die nicht 10 Minuten vor Einlass anwesend sind, abweisen müssen.",
  ],
  [
    "Hinweis: Ausdruck aus Online-Lexikon, gefunden in Verwaltungsgebäude der AlexKäuf Werkstätten GmbH]",
    "Wukifedia",
    "Einstellung Strafverfahren",
    "Die Einstellung eines Strafverfahrens bedeutet de Beendigung eines Verfahrens wobei die Schuldfrage ungeklärt bleibt. Die Unschuldsvermutung bleibt bestehen.",
    "Die Staatsanwaltschaft muss ein Verfahren gemäß § 170 Abs. 2 StPO zwingend einstellen, wenn kein hinreichender Tatverdacht besteht. Ein hinreichender Tatverdacht liegt vor, wenn eine erhebliche Wahrscheinlichkeit besteht, dass die vorgeworfene Tat in der Hauptverhandlung bewiesen werden kann und zu einer Verurteilung führt.",
    "Als Gründe für einen fehlenden hinreichenden Tatverdacht kommen in Frage:",
    "- Mangel an Beweisen",
    "- Der Täter oder die Täterin kann nicht ermittelt werden",
    "Rechtliche Gründe für die Einstellung eines Strafverfahrens",
    "- Das Verhalten des Täters ist gerechtfertigt oder entschuldigt",
    "- Verfahrensfehler wie z.B. ein abgelaufene Antragsfrist",
    "- Strafunmündigkeit",
    "- Verjährung",
    "- Tod des Beschuldigten bzw. Angeklagten während des Verfahrens",
  ],
  [
    "GUMBL mail",
    "Gesendet: 13.01.2021, 20:50 Uhr",
    "Von: Simon Käufer <simon-kaeufer@gumblmail.de>",
    "An: Alexander Käufer <alexk@gumblmail.de>",
    "Betreff: Was bedeutet das?",
    "Hallo Papa,",
    "ich erreiche dich telefonisch nicht. Hast du gewusst, dass in unseren Werkstätten fehlerhafte Ersatzteile verbaut werden? Wenn die Staatsanwaltschaft die Ermittlungen wieder aufrollt, kommen womöglich hohe Schadenersatzkosten und Strafen auf uns zu.",
    "Das gefährdet das Unternehmen. Ich bin dir dankbar, dass du mich zum Geschäftsführer gemacht hast, denn das wollte ich ja unbedingt, aber das gibt dir nicht das Recht unser Familienunternehmen zu ruinieren! Dazu darf es nicht kommen. Alles steht und fällt damit, dass wir erneute Ermittlungen verhindern (falls du darin verwickelt sein solltest). Ich muss deshalb wissen was Sache ist!",
    "Melde dich bitte schnellstmöglich bei mir…",
    "Simon",
  ],
  [
    "DWL-Logistik",
    "Wir haben heute versucht 1 Paket zuzustellen",
    "Name: Erwin Müller",
    "Datum: 02.02.2021 8:19 Uhr",
    "Sendungsnummer 1478211789972",
    "WO IST IHRE SENDUNG JETZT?",
    "Wir haben Ihre Sendung bei Nachbar abgegeben",
    "Name: Carolin Käufer",
    "Adresse: Lange Straße 2",
  ],
  [
    "FC Grabenbruck e.V.",
    "Vereinssitzung",
    "02.02.2021",
    "I. Eröffnung",
    "Ludwig Liebig eröffnete die ordentliche Sitzung des Vereinsvorstandes am 02.02.2021 um 8:45 Uhr im Vereinsheim „Arbeitssieg“ des FC Grabenbruck.",
    "II. Feststellung der Anwesenheit",
    "Lilly Liebig (Schriftführerin) stellte die Anwesenheit fest. Der gesamte Vorstand war anwesend.",
    "III. Feststellung der Tagesordnung",
    "Lilly Liebig (Schriftführerin) las die Tagesordnung vor. Es gab keine Änderungswünsche.",
    "IV. Offene Fragen",
    "a) Finanzierung der Trikots der nächsten Saison",
    "b) Rasenerneuerung Sportplatz",
    "c) Organisation Vereinsfest",
    "V. Neue Geschäftsvorgänge",
    "a) Aktion zur Gewinnung neuer Mitglieder",
    "b) Suche nach einem neuen Trainer für die D-Jugend Mädchen und Jungs",
    "VI. Vertagung",
    "Ludwig Liebig hat die Sitzung um 09:45 Uhr vertagt.",
    "Protokoll vorgelegt von: Lilly Liebig",
    "Protokoll genehmigt von: Tom Müller",
  ],
];

export default function Bewitext(props) {
  const { auf, setAuf } = useContext(AppContext);
  return (
    <div className="centering wcentering bckr">
      <p className="reihe" onClick={() => setAuf(!auf)}>
        {" "}
        {auf ? (
          <div>
            <RemoveIcon fontSize="inherit" style={{ color: "#555" }} />
          </div>
        ) : (
          <div>
            <AddIcon fontSize="inherit" style={{ color: "#555" }} />
          </div>
        )}
        <span className="antword"> Textversion</span>
      </p>
      {auf ? (
        <div>
          {btext[props.indb].map((school, index) => (
            <div className="strr">
              <p>{school}</p>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
