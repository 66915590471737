import React from "react";
import contactp from "../contactp.png";
import { useNavigate } from "react-router-dom";

export default function Contact() {
  const navigate = useNavigate();
  function handleClick() {
    navigate("/impressum");
  }

  return (
    <div className="centering wcentering">
      <p>Für Anfragen und Auskünfte stehen Wir Ihnen gerne zur Verfügung!</p>
      <p>Das Spiel "Tod in Tillstetten" ist ein Produkt von</p>
      <hr />
      <div className="margin-top">
        <img src={contactp} alt="Contact Info" />
      </div>
      <hr />
      <p onClick={handleClick} className="impress">
        Impressum
      </p>
    </div>
  );
}
