import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ESingle from "../components/crezept/ESingle";

export default function Single() {
  const navigate = useNavigate();
  function zuru() {
    navigate("/rezept");
  }

  const [auf, setAuf] = useState(false);

  return (
    <Fragment>
      <div className="rbckr">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={{ background: "#527318" }}>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="arrowBack"
                sx={{ mr: 2 }}
                onClick={zuru}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
                align="center"
              >
                Tipps Single-Portal
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="marginround">
            <div className="rcentering txtkleiner txthellg abold">
              <div className="abstandbar"></div>
              <p>
                Auf das Single-Portal für die Undercover-Ermittlung bei Jana
                Jäckel kommt Ihr über den QR-Code oder{" "}
                <a
                  href="https://www.liebesingles.de"
                  target="liebesingles.de"
                  rel="noopener noreferrer"
                >
                  https://www.liebesingles.de
                </a>
                .
              </p>
              <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 1, sm: 16, md: 18 }}
                onClick={() => setAuf(!auf)}
              >
                {Array.from(Array(3)).map((_, index) => (
                  <ESingle indi={index} />
                ))}
              </Grid>
            </div>
          </div>
        </Box>
        <div className="abstandu"></div>
      </div>
    </Fragment>
  );
}
