import React from "react";
import Hinweis from "./Hinweis";

const hints = [
  [
    "Bibliothek Ausweis & Beleg:",
    "Uns liegt ein Foto des Büchereiausweises von Bianca Bauer vor. Die Ausweisnummer auf dem Ausleihbeleg ist identisch mit der Ausweisnummer von Bianca Bauer.",
    "Wer ist Bianca Bauer? Das Poster und der grüne Auftragsbrief können weitere Hinweise geben. Lassen sich aus den Informationen auf dem Beleg weitere Informationen gewinnen?",
  ],
  [
    "Todesanzeige Karl Liebig",
    "Vergleicht die Todesanzeige mit den Zeitungsartikeln.",
    "Gibt das Todesdatum und die erwähnten Hinterbliebenen einen Hinweis auf ein mögliches Motiv?",
  ],
  [
    "LieferBit Online-Shop-Bestellungen",
    'Aus dem Auftragsschreiben (grünes Briefpapier) wissen wir, dass die Tatwaffe ein Golfschläger aus dem Set "Greenschoen" ist. Zudem wissen wir aus dem Auftragsschreiben, dass der/die Täter/in anonym bestellt hat. Eine der Bestellungen ist also vom Täter! Die zusätzlich im Warenkorb mit dem Golfset bestellten Waren spielen eine Rolle.',
    "Durchsucht die Emails von Alexander Käufer (über www.gumblmail.de), vielleicht taucht dort eine der Zusatzwaren auf? Das könnte eine heiße Spur sein!",
  ],
  [
    "Fingerabdrücke",
    "Gleicht die Fingerabdrücke der Polizeiakte mit denen auf dem anonymen Brief ab. Der anonyme Brief war an Alexander Käufer adressiert, hat der Schreiber des Briefs ein Motiv?",
    "Die Fingerabdrücke zeigen, dass ein anderer Empfänger als Alexander Käufer diesen Brief geöffnet hat. Könnte sich diese Person unter Druck gesetzt fühlen?",
  ],
  [
    'Bestenliste des Online-Spiels "Flottfight"',
    'Der oder die Spieler/in mit dem Nutzernamen "PowerRider" hat offenbar zur Tatzeit einen neuen Rekord aufgestellt.',
    'Wer könnte "PowerRider" sein? Auf der Website des Gestüts Steilfels (www.gestuet-steilfels.de) gibt es ein Werbebanner. Wenn ihr auf den Werbebanner klickt, könntet ihr einen Hinweis erhalten.',
  ],
  [
    "Arbeitsunfähigkeitsbescheinigung",
    "Erik Lindt hat sich am Tattag krankschreiben lassen. Aber wer ist diese Person? Über die Emails von Alexander Käufer (seine Email-Adresse ist alexk@gumblmail.de beim Mailprovider www.gumblmail.de) kommen wir über eine Email von Nico Neumann auf die Homepage der TillSt Autoteile GmbH. Dort erfahren wir wer Erik Lindt ist.",
    "Was hat es für Auswirkungen wenn Erik Lindt nicht zur Arbeit kommt? Wichtig ist in diesem Zusammenhang das Auto auf der Website und ein Strafzettel für Falschparken.",
  ],
  [
    "Ausgedruckte Email von Simon an Alexander Käufer",
    'Simon Käufer könnte diese Email geschrieben haben, nachdem er den Wukifedia-Artikel zum Thema "Einstellung Strafverfahren" gefunden hat. Ergibt sich daraus ein mögliches Motiv?',
    "Wir erfahren hier die Email-Adresse von Alexander Käufer. Im grünen Auftragsbrief steht, dass es wichtig sein könnte uns Zugang zu den privaten Emails von Alexander Käufer zu verschaffen (auf www.gumblmail.de). Die Email-Adresse ist dazu ein wichtiger Schritt",
  ],
  [
    "Chat-Verlauf Smartphone Ludwig Liebig",
    "Wir erfahren, dass Heike Hoppe ihren Mann für unschuldig hält, und einen Lügendetektor-Test mit ihrem Mann Hans Hoppe anbietet. Fall Hans Hoppe tatsächlich unschuldig im Gefängnis sitzt. Könnte das ein Motiv für Heike Hoppe sein, sich an Alexander Käufer zu rächen?",
    "Wir erfahren hier auch, dass Jana Jäckel am 03. Januar vom Flughafen abgeholt wurde. Gibt es weitere Hinweise, die mit dem Flughafen zu tun haben?",
  ],
  [
    "Chat-Verlauf Smartphone Carolin Käufer",
    "Im Chat vom 20. Januar erfahren wir, dass Carolin Käufer durch eine mögliche Scheidung von Alexander in finanzielle Schwierigkeiten gerät. Ein Motiv? Die Gesprächspartnerin ist wütend, dass Markus Mink keine Beförderung auf den Geschäftsführerposten in Alexander Käufers Firma bekommen hat. Vermutlich ist das Markus Minks Frau, Theresa Mink. Haben damit auch sie und Markus Mink ein Motiv?",
    "Im Chatverlauf vom 22. Januar erfährt Michaela Maurer, dass Carolin wegen der Scheidung von Alexander das Pferd Maxi verkaufen muss. Was hat Michaela mit dem Pferd Maxi zu tun, und könnte sie ein Interesse haben, die Scheidung zu verhindern, damit Carolin das Pferd nicht verkauft? Motiv?",
  ],
  [
    "Lügendetektortest Hans Hoppe",
    "Die Auswertung ergibt, dass Hans Hoppe die Wahrheit sagt, und tatsächlich unschuldig ist am Tod von Karl Liebig.",
    "Welche Schlussfolgerung werden Jana Jäckel, Lilly und Ludwig Liebig daraus ziehen? Die drei erinnern sich sicher noch an die Gerichtsprozesse, über die wir in den Zeitungsartikeln lesen können.",
  ],
  [
    "Fundsachen des Flughafens",
    "Zum Tatzeitpunkt am 02.02.2021 wurde eine rote Lesebrille abgeholt.",
    "Auf dem Poster mit den Tatverdächtigen sehen wir, dass Jana Jäckel eine rote Brille hat. Zudem wissen wir aus dem Chat-Verlauf von Ludwig Liebigs Smartphone, dass Jana am 03. Januar vom Flughafen abgeholt wurde. Das ist der Tag an dem die Brille im Fundbüro abgegeben wurde. Jana Jäckel hat also ein Alibi.",
  ],
  [
    "Brief des Pferdegutachters Anton Anderson",
    "Carolin Käufer möchte den Wert ihres Pferdes Maxi schätzen lassen. Gibt es Grund zur Annahme, dass mit Maxis Herkunft etwas nicht stimmt?",
    "Von wem wurde Maxi gekauft? Und hat diese Person ein Interesse daran, einen Verkauf von Maxi zu verhindern? Die Überprüfung der Informationen auf www.gestuet-steilfels.de könnte neue Anhaltspunkte zum Wert von Maxi geben.",
  ],
  [
    "Besucherrichtlinien der JVA Grabenbruck",
    "der 02.02.2021 war ein Dienstag. An diesem Tag können nur Ehepartner Besuche machen. Da Hans Hoppe an diesem Tag Besuch hatte, und die Besuchszeit auf den Tatzeitpunkt fällt, hat Heike Hoppe ein Alibi.",
  ],
  [
    "Brief der Kanzlei Dr. Bernhard Schröder",
    "Carolin Käufer bekommt im Falle einer Scheidung nur wenig Geld. Wir wissen aus dem grünen Auftragsbrief, dass Alexander Käufer mit seiner neuen Freundin zusammengezogen ist. Eine Scheidung ist also wahrscheinlich. Ein Motiv für Carolin Käufer?",
  ],
  [
    "Brief von Michaela Maurer an Carolin Käufer",
    "Michaela Maurer hat den Kauf von Maxi 2019 abgewickelt und organisiert. Wegen ihrer Scheidung will Carolin Käufer das Pferd verkaufen.",
    "War beim Verkauf des Pferdes von Michaela an Carolin alles sauber? Die anderen Hinweise bringen da Licht ins Dunkel. Könnte Michaela am Ende ein Interesse daran haben eine Scheidung zwischen Alexander und Carolin Käufer zu verhindern? Ist das ein Motiv?",
  ],
  [
    "DWL Logistik Paketzettel",
    "zum Tatzeitpunkt hat Carolin Käufer für ihren Nachbarn ein Paket entgegengenommen. Aus dem grünen Auftragsbrief wissen wir, dass Carolin Käufer allein in ihrem Haus lebt. Wir können also davon ausgehen, dass die Paketannahme ein Alibi ist.",
  ],
  [
    "Verwarnung wegen Falschparken / Knöllchen",
    "Das Kennzeichen gehört zum Lieferwagen der TillSt Autoteile GmbH. Das erkennen wir auf dem Foto der Website der TillSt Autoteile GmbH. Diese Website finden wir wiederum über die privaten Emails von Alexander Käufer (über www.gumblmail.de und dann im Email-Konto alexk@gumblmail.de die Email von Nico Neumann).",
    "Wer auch immer zum Tatzeitpunkt mit dem Lieferwagen in Grabenbruck unterwegs war und dort geparkt hat, hat ein Alibi.",
  ],
  [
    "Wukifedia-Ausdruck",
    "Aus der ausgedruckten Email von Simon an Alexander Käufer, wissen wir, dass Simon in der AlexKäuf Werkstätten GmbH Geschäftsführer ist. Auch Markus Mink arbeitet dort. Dass mit einem Marker angestrichen wurde, dass der Tod eines Beschuldigten die Einstellung eines Strafverfahrens zur Folge hat erscheint sehr verdächtig.",
    "Was für ein Strafverfahren könnte das sein? Und wer hat ein besonderes Interesse im Umfeld der AlexKäuf Werkstätten GmbH, dass ein mögliches Strafverfahren verhindert wird?",
  ],
  [
    "GUMBL Bewertungen der AlexKäuf Werkstätten GmbH",
    "Wir wissen, dass Alexander Käufer seinen Sohn zum Geschäftsführer seiner Firma gemacht hat (ausgedruckte Email von Simon Käufer an Alexander Käufer).",
    "Könnte die Unzufriedenheit der Kunden dazu führen, dass Simon Käufer als Geschäftsführer entlassen wird? Wäre das ein Motiv für Simon Käufer?",
  ],
  [
    "Vereinssitzung des FC Grabenbruck e.V.",
    "Der Vereinsvorstand tagt an einem Dienstag Vormittag. Es müssen wichtige Dinge besprochen worden sein. Jedenfalls sind laut Protokoll Lilly und Ludwig Liebig anwesend. Da die Entfernung zum Tatort um diese Uhrzeit zu groß ist, können die beiden nicht die Täter gewesen sein.",
  ],
  [
    "Abstammungszertifikat Gestüt Steilfels",
    "Überprüft die Angaben des Zertifikats auf der Website des Gestüts (www.gestuet-steilfels.de). Ist das Zertifikat womöglich eine Fälschung? Wenn ja, wer hat das Zertifikat gefälscht, und warum?",
  ],
  [
    "Die Emails von Alexander Käufer hacken (Tipp 1)",
    'in den Unterlagen findet Ihr eine ausgedruckte Email von Simon Käufer an seinen Vater. Dort seht Ihr, dass Alexander Käufers Email-Adresse "alexk@gumblmail.de" ist.',
    "Mit der Info aus dem Auftrag (grünes Briefpapier), dass die Website des Email-Providers www.gumblmail.de ist, könnt Ihr nun nach Wegen suchen die Email zu hacken.",
  ],
  [
    "Die Emails von Alexander Käufer hacken (Tipp 2)",
    'Das Hacken von Emails ist nicht einfach. Vielleicht findet Ihr eine Sicherheitslücke über die Option "Passwort vergessen"?',
    "Über andere Hinweise seid ihr vielleicht in der Lage die geheimen Sicherheitsfragen zu beantworten. Alexander Käufer hat sie für den Fall so hinterlegt, falls er einmal sein eigenes Passwort vergisst.",
  ],
  [
    "Die Emails von Alexander Käufer hacken (Tipp 3)",
    'Die Sicherheitsfragen sind nicht einfach: Michaelas Alias-Name in Flottfight findet Ihr auf Michaelas Website, die über eine Werbeanzeige auf der Website des Gestüts Steilfels gefunden werden kann. Der Alias-Name lautet "PowerRider". Der Name des Vereinsheims des FC Grabenbruck wird auf dem Sitzungsprotokoll des Vorstandes erwähnt. Das Vereinsheim heißt "Arbeitssieg". Der beste Mitarbeiter ist Markus Mink. Seine Frau Theresa Mink erzählt in einem Chat mit Carolin Käufer, dass Markus eigentlich von Alexander hätte befördert werden sollen.',
    'Die Lösung der Sicherheitsfrage ist also "PAM"',
  ],
  [
    "Die Emails von Alexander Käufer - Markus Mink",
    'Unter "Gesendet" sehen wir, dass Alexander Käufer sich entschlossen hat Markus Mink anstelle seines Sohnes Simon Käufer zum Geschäftsführer zu ernennen. Dazu hat er einen Notartermin vereinbart, bei dem Markus Mink erscheinen soll.',
    "Damit entfällt für Markus Mink und seine Frau Theresa Mink das Motiv. Sie haben keinen Grund mehr, Alexander Käufer die entgangene Beförderung zu verübeln. Simon Käufer dagegen hätte einen weiteren Grund wütend auf seinen Vater zu sein. Die Email vom 02.02.2021, also dem Todeszeitpunkt, gibt Markus und Theresa Mink ein Alibi. Sie warten beim Notar.",
  ],
  [
    "Die Emails von Alexander Käufer - Nico Neumann",
    "Nico Neumann scheint eine zwielichtige Gestalt zu sein. Aus den Emails können wir schließen, dass Alexander Käufer mit ihm Geschäfte gemacht hat. Da Hans Hoppe durch den Lügendetektortest als Lieferant der gefälschten Ersatzteile nicht in Frage kommt, deutet alles auf Nico Neumann hin.",
    "Der Link in Nico Neumanns Email könnte weitere Hinweise enthalten.",
  ],
  [
    "Die Emails von Alexander Käufer - Simon Käufer",
    "Simon Käufer hat mehrere Motive. Die Email mit dem Hinweis auf die Nussriegel SweetUnder ist ein entscheidender Hinweis, der Simon Käufer entlasten oder belasten könnte.",
    "Untersucht andere Hinweise, die nicht unmittelbar mit den Emails von Alexander Käufer zu tun haben. Wo tauchen die Nussriegel SweetUnder noch auf?",
  ],
  [
    "Die Website von Michaela Maurer",
    "Die Web-Adresse der Website lautet tillreiten.web.app ; auf diese Website stoßt ihr, wenn ihr auf Michaelas Werbeanzeige auf der Website des Gestüts Steilfels (www.gestuet-steilfels.de) klickt.",
    'Wir erfahren auf der Website, dass Michaela Reitlehrerin ist. Diese Verbindung ist wichtig, da Carolin Käufer ein teures Pferd gekauft hat. Von wem? Zudem erfahren wir, dass Michaelas Hobby das Spielen des Online-Spiel "Flottfight" ist. Sie nennt ihren Usernamen, der für die Bestimmung ihres Alibis wichtig ist.',
  ],
  [
    "Die Website von Nico Neumann (Tillst Autoteile GmbH)",
    "Die Web-Adresse der Website lautet tillst.web.app ; auf diese Website stoßt ihr über die gehackten Emails von Alexander Käufer über einen Link in einer Email von Nico Neumann.",
    "Auf der Website erfahren wir, dass im Betrieb von Nico Neumann nur er selbst und ein Mitarbeiter arbeiten (vergleicht den Krankenschein!). Da laut Website alle Auslieferungen persönlich vorgenommen werden: was bedeutet das, wenn ein Mitarbeiter krank ist? Gibt das Nummernschild des Autos (zusammen mit anderen Belegen) einen Hinweis auf ein Alibi von Nico Neumann?",
  ],
  [
    "Die Website des Gestüts Steilfels",
    "Die Web-Adresse des Gestüts lautet www.gestuet-steilfels.de ; diese Adresse wird erwähnt im Brief des Pferdegutachters Anton Anderson. Auf der Website sehen wir in den Stammbäumen, dass Carolin Käufers Pferd Maxi unmöglich von Redouane und Grand abstammen kann, weil Redouane zum Geburtszeitpunkt von Maxi mit einem anderen Fohlen trächtig war. Das Zertifikat von Maxi ist also gefälscht!",
    "Schaut Euch auch die Werbeanzeige auf der Website des Gestüts näher an.",
  ],
];

export default function Friendly() {
  return (
    <div className="centering wcentering bckr">
      <h2>Hinweise</h2>
      <div>
        {hints.map((school, index) => (
          <div className="strr">
            <Hinweis hint={school} index={index} />
          </div>
        ))}
      </div>
    </div>
  );
}
