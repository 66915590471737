import React, { useEffect, useState } from 'react'
import firebase from '../firebase.js'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState([])
  const [isLogged, setIsLogged] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      console.log('userrr', user)
      setIsLogged(user)
      if (user != null) {
        const ref = firebase.firestore().collection('group').doc(user.uid)
        ref
          .get()
          .then(function (doc) {
            if (doc.exists) {
              setCurrentUser([doc.data().location, doc.data().role])
              setLoading(false)
            } else {
              console.log('No such document userlog!')
              setCurrentUser(['test', 'admin'])
              setLoading(false)
            }
          })
          .catch(function (error) {
            console.log('Error getting document userlog:', error)
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    })
  }, [])

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh',
        }}
      >
        <h1>Loading :)</h1>
      </div>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isLogged,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
