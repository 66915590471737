import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EAntrag from "../components/crezept/EAntrag";

export default function Antrag() {
  const navigate = useNavigate();
  function zuru() {
    navigate("/rezept");
  }

  const [auf, setAuf] = useState(false);

  return (
    <Fragment>
      <div className="rbckr">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={{ background: "#527318" }}>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="arrowBack"
                sx={{ mr: 2 }}
                onClick={zuru}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
                align="center"
              >
                Tipps Antrag Polizei-Portal
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="marginround">
            <div className="centering rcentering txtkleiner txthellg abold">
              <div className="abstandbar"></div>
              <div>
                <p>
                  Auf die Seite mit dem Zugang zum Intranet der Polizei
                  Tillstetten kommt Ihr über den QR-Code oder{" "}
                  <a
                    href="https://pol-tillstetten.web.app"
                    target="liebesingles.de"
                    rel="noopener noreferrer"
                  >
                    https://pol-tillstetten.web.app
                  </a>
                  .
                </p>

                <Grid
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 1, sm: 16, md: 18 }}
                  onClick={() => setAuf(!auf)}
                >
                  {Array.from(Array(3)).map((_, index) => (
                    <EAntrag indi={index} />
                  ))}
                </Grid>
              </div>
            </div>
          </div>
        </Box>

        <div className="abstandu"></div>
      </div>
    </Fragment>
  );
}
