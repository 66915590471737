import React from "react";
import { useNavigate } from "react-router-dom";

export default function Tipps() {
  const navigate = useNavigate();
  function handleClick() {
    navigate("/");
  }

  return (
    <div className="centering wcentering">
      <p>
        Falls ihr nicht weiterkommt findet ihr Tipps auf der Startseite. Dort
        findet ihr auch die Lösung.{" "}
      </p>

      <p className="anlei">Geht einfach auf:</p>
      <p className="reihe">
        <div></div>
      </p>
      <div className="hinwifarbe">
        <h3 onClick={handleClick}>https://www.tillstetten.de</h3>
      </div>
    </div>
  );
}
